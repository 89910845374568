// libraries
import classNames from "classnames";
import React from "react";

// constants
import { LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";

// components
import ResponsiveContainer from "@vahak/core/dist/components/responsive-container/ResponsiveContainer";
import LorryTypeCard from "@vahak/core/dist/components/lorry-type-card/LorryTypeCard";
// styles
import styles from "./LorryTypeFilterOptions.module.scss";

export interface LorryTypeFilterOptionsProps {
    handleSelectedLorryType: (value: typeof LORRY_TYPES[0] | undefined) => void;
    selectedLorryType: typeof LORRY_TYPES[0] | undefined;
    showClearBtn?: boolean;
    className?: string;
}

const LorryTypeFilterOptions = ({
    handleSelectedLorryType,
    selectedLorryType,
    showClearBtn,
    className
}: LorryTypeFilterOptionsProps) => {
    const onSelectLorryType = (value?: typeof LORRY_TYPES[0]) => {
        // Callback
        handleSelectedLorryType(value?.id === selectedLorryType?.id ? undefined : value);
    };

    // Clear selected filter
    const onClickClear = () => {
        handleSelectedLorryType(undefined);
    };
    return (
        <div className={classNames(styles.lorryTypeFilterWrapper)}>
            <div className={styles.header}>
                <h3>Lorry Type</h3>
                {showClearBtn && (
                    <span className={styles.clearFilter} onClick={onClickClear}>
                        Clear
                    </span>
                )}
            </div>
            <ResponsiveContainer className={classNames(styles.lorryTypeCardWrapper)}>
                {LORRY_TYPES.map((lorry, index) => (
                    <LorryTypeCard
                        lorryData={lorry}
                        handleLorryChecked={onSelectLorryType}
                        isSelected={lorry.id === selectedLorryType?.id}
                        key={index}
                    />
                ))}
            </ResponsiveContainer>
        </div>
    );
};

export default LorryTypeFilterOptions;
