import React, { useEffect, useState } from "react";

import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { MINIMUM_QUANTITY } from "@vahak/core/dist/constants/minimumQuantity";
import { Filters } from "../../../marketplace/common/types";

// import Styles from "./MobileFilters.module.scss";
import { LorryTypeCardProps } from "@vahak/core/dist/components/lorry-type-card/LorryTypeCard";
import MobileFilterContainer from "@vahak/core/dist/components/mobile-filters/MobileFilterContainer";
import LorryTypeFilter from "@vahak/core/dist/components/mobile-filters/LorryTypeFilter";
import QtyFilter from "@vahak/core/dist/components/mobile-filters/QtyFilter";
import { onSelectLorryTypeFilter } from "../../../marketplace/common/onSelectLorryTypeFilter";
import router, { useRouter } from "next/router";
import Checkbox from "@vahak/core-ui/dist/components/Checkbox";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../../../constants/adminOperation";

export interface MobileFiltersProps {
    onClickClose: () => void;
    selectedFilters: Filters | undefined;
    onApplyFilter: (filters: Filters | undefined) => void;
}

const MobileFilters: React.FC<MobileFiltersProps> = ({
    onClickClose,
    // selectedLorryType,
    // onSelectLorryType,
    // selectedQty,
    // onSelectQty,
    // onClickClearAll,
    selectedFilters,
    onApplyFilter
}) => {
    //
    const route = useRouter();
    const isLoadMkt = route.pathname.includes("/market/load");

    const [tempFilters, updateTempFilters] = useState<Filters | undefined>();
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const { checkPermission } = useCsDashboard();
    const isTso = checkPermission(ADMIN_OPERATION.tagAgent);

    const trackingEventAutoCalculatedData = {
        source_screen: isLoadMkt ? "load_market" : "lorry_market",
        filter: isLoadMkt ? "load_market" : "lorry_market",
        ...(isTso && {
            source_screen: isLoadMkt ? "tso_load_market" : "tso_lorry_market"
        })
    };

    const trackClick = (selection_from: string, name: string, additionalData: GenericObject = {}) => {
        const data = {
            ...trackingEventAutoCalculatedData,
            selection_from: selection_from,
            ...additionalData
        };

        sendGAandMoEngageEvent({
            name,
            data
        });
    };

    const _onSelectLorryType: LorryTypeCardProps["handleLorryChecked"] = (value) => {
        onSelectLorryTypeFilter(updateTempFilters, undefined, value);

        if (value) {
            const result = value as any;
            const trackingData = {
                filter_selection: [
                    ...(tempFilters?.qty ? [tempFilters?.qty?.label] : []),
                    ...(result?.id ? [result?.label] : [])
                ]
            };
            trackClick("vehicle_type", GA4EventNames.MARKET_PLACE["filters_selected"], trackingData);
        }
    };

    const _onSelectQty = (qty?: typeof MINIMUM_QUANTITY[0]) => {
        updateTempFilters((state) => ({ ...state, qty }));

        if (qty) {
            const trackingData = {
                filter_selection: [
                    ...(qty ? [qty?.label] : []),
                    ...(tempFilters?.lorryType ? [tempFilters?.lorryType?.label] : [])
                ]
            };
            trackClick("vehicle_capacity", GA4EventNames.MARKET_PLACE["filters_selected"], trackingData);
        }
    };

    // const _onDeselectQty = (qty: typeof MINIMUM_QUANTITY[0]) => {
    //     updateTempFilters((state) => ({ ...state, qty: undefined }));
    //     // onSelectQty(selectedQty.filter((q) => q.id !== qty.id));
    // };

    const _onClearAll = () => {
        updateTempFilters(undefined);
    };

    const _onApplyFilter = () => {
        onApplyFilter(tempFilters);
    };

    useEffect(() => {
        updateTempFilters(selectedFilters);
    }, [selectedFilters]);

    let filterItems = [
        {
            title: "Lorry Type",
            content: <LorryTypeFilter selected={tempFilters?.lorryType} onSelect={_onSelectLorryType} />
        },
        {
            title: "Minimum Quantity",
            content: (
                <QtyFilter
                    showLitres={tempFilters?.lorryType?.id === 6}
                    selected={tempFilters?.qty}
                    onSelect={_onSelectQty}
                />
            )
        }
    ];

    if ((router.query.params as string[])[0] === "load") {
        filterItems.push({
            title: "Assured Loads",
            content: (
                <Checkbox
                    value={tempFilters?.isAssuredLoad || false}
                    id="assured-load-filter"
                    label={<span>Assured Loads</span>}
                    name="assured-load"
                    size="md"
                    onChange={(e: any) => {
                        updateTempFilters((state) => ({ ...state, isAssuredLoad: e.target.checked }));
                    }}
                />
            )
        });
    }

    return (
        <MobileFilterContainer
            tempFilters={tempFilters}
            onApplyFilter={_onApplyFilter}
            onClearAll={_onClearAll}
            onClickClose={onClickClose}
            appliedTempFiltersCount={(tempFilters?.qty ? 1 : 0) + (tempFilters?.lorryType ? 1 : 0)}
            filterItems={filterItems}
        />
    );
};

export default MobileFilters;
