import { ClientHookInfiniteQueryOptions, useVahakInfiniteQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";
import { generateUrlParamsFromArray } from "@vahak/core/dist/methods/generateUrlParamsFromArray";
import queryNames from "../../queryNames";
import { generateUrlParamsFromObject } from "@vahak/core/dist/methods/generateUrlParamsFromObject";
import { LorryMarketCard, LorryMarketParam, LorryMarketType } from "./type";

interface aggregatedDataType {
    lorry_cards: LorryMarketCard[];
    page_number: number;
    total_pages: number;
    total_records: number;
}

export const useGetLorryMarketInfiniteQueryV2 = (
    {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        capacity_string,
        l,
        preloads,
        vehicle_verified_only,
        connections_only,
        lorry_type_counts,
        membership_user_filter,
        payment_linked_only,
        isAdmin,
        lorryId,
        destination_place_id,
        source_place_id
    }: LorryMarketParam,
    options: ClientHookInfiniteQueryOptions<LorryMarketType, unknown, aggregatedDataType>
) => {
    const { GET } = useVahakHttpClient();

    function getLoads({ pageParam = 1 }) {
        const payload = {
            page: pageParam,
            c_i,
            lorry_type_id,
            city_id,
            state_id,
            destination_city_id,
            destination_state_id,
            capacity_string,
            l,
            vehicle_verified_only,
            connections_only,
            lorry_type_counts,
            membership_user_filter,
            payment_linked_only,
            lorryId,
            destination_place_id,
            source_place_id
        };
        const urlString = generateUrlParamsFromObject(payload);
        let idString = lorryId && generateUrlParamsFromArray(lorryId, "id");
        let preloadsString =
            preloads &&
            generateUrlParamsFromArray(
                preloads,
                "preloads",
                !isAdmin ? (["agent_details"] as LorryMarketParam["preloads"]) : undefined
            );

        return GET<LorryMarketType>(
            `${API.LORRY_MARKET_V2}${idString ? "&" + idString : ""}${urlString}${
                preloadsString ? "&" + preloadsString : ""
            }`
        );
    }

    return useVahakInfiniteQuery<LorryMarketType, unknown>([queryNames.getLorryMarketInfiniteQueryV2], getLoads, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            let aggregatedData: aggregatedDataType = {
                lorry_cards: [],
                page_number: 0,
                total_pages: 0,
                total_records: 0
            };

            data.pages.forEach((data) => {
                aggregatedData = {
                    lorry_cards: [...aggregatedData.lorry_cards, ...(data.data?.lorry_cards ?? [])],
                    page_number: data.data.page_number,
                    total_pages: data.data.total_pages,
                    total_records: data.data.total_records
                };
            });
            /**
             * Enclosing aggregatedData inside an array, just to fix the type script
             * as the onSuccess returns only aggregatedData[] - array type
             */
            options.onSuccess([aggregatedData]);
        },
        refetchOnWindowFocus: false
    });
};
