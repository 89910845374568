import React, { useContext } from "react";

// Styles
import Styles from "./LocationSearch.module.scss";
// import "./SearchStyleOverride.scss";

// Assets
import DoubleArrow from "@vahak/core/dist/icons/doubleArrow.svg";

// Components
import Search from "@vahak/core/dist/components/search/Search";
import { usePlaceSearch } from "@vahak/core/dist/hooks/useSearch";
import { AppContext } from "@vahak/core/dist/app-context";
import { useTopDest } from "@vahak/core/dist/hooks/useTopDest";
import classNames from "classnames";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";

interface LocationSearchProps {
    src: any;
    onSelectSrc: any;
    dest: any;
    onSelectDest: any;
    mktType: MarketType;
}

const LocationSearch = ({ src, onSelectSrc, dest, onSelectDest, mktType }: LocationSearchProps) => {
    const isLoadMkt = mktType === "load";
    const { recentTransporterCitySearches } = useContext(AppContext);
    const { topDests: _topDests, setTopDests } = useTopDest({ max: 2 });

    // Hooks - Source location
    const {
        isSearchLoading: isSrcSearchLoading,
        labelKey: srcLabelKey,
        onItemSelected: onSrcSelected,
        onSearchInput: onSrcSearchInput,
        searchOptions: srcSearchOptions,
        // selectedItem: src,
        searchText: srcSearchText
        // setSearchText: setSrcSearchText
    } = usePlaceSearch({
        locationParamsOption: {
            allowState: true,
            source: isLoadMkt
                ? LocationSearchSource.LoadMarketPlaceSearchSource
                : LocationSearchSource.LorryMarketPlaceSearchSource
        },
        showCurrentLocationOption: true,
        defaultOptions: recentTransporterCitySearches,
        onSelectItem: (selected: any) => {
            onSelectSrc(selected[0]);
        }
    });

    // Hooks - Destination location
    const {
        isSearchLoading: isDestSearchLoading,
        labelKey: destLabelKey,
        onItemSelected: onDestSelected,
        onSearchInput: onDestSearchInput,
        searchOptions: destSearchOptions,
        // selectedItem: dest,
        searchText: destSearchText
        // setSelectedItem: setSelectedDest
    } = usePlaceSearch({
        locationParamsOption: {
            allowState: true,
            source: isLoadMkt
                ? LocationSearchSource.LoadMarketPlaceSearchDestination
                : LocationSearchSource.LorryMarketPlaceSearchDestination
        },
        showCurrentLocationOption: true,
        defaultOptions: recentTransporterCitySearches,
        onSelectItem: (selected: any) => {
            onSelectDest(selected[0]);
            setTopDests(selected[0]);
        }
    });

    return (
        <div className={classNames(Styles.locationContainer)}>
            <div className={Styles.sourceCol}>
                <div className={classNames(Styles.searchInputBox)}>
                    <Search
                        isLoading={isSrcSearchLoading}
                        id="src-search"
                        labelText="PICKUP"
                        onSearch={onSrcSearchInput}
                        onSelectItem={onSrcSelected}
                        options={srcSearchOptions}
                        labelKey={srcLabelKey}
                        selectedItem={src}
                        searchText={srcSearchText}
                        placeholder="Enter your loading point"
                        minLength={0}
                        inputWrapperClassName={classNames(Styles.searchWrapper, "searchLocation")}
                        containerClassName={classNames(Styles.searchContainer, Styles.srcSearchContainer)}
                        containerStyles={{
                            height: "60px",
                            borderRadius: "10px 0 0 10px"
                        }}
                        labelClassName={Styles.searchLocationLabel}
                    />
                </div>
            </div>
            <span className={Styles.rightArow}>
                <DoubleArrow />
            </span>
            <div className={Styles.destCol}>
                <div className={classNames(Styles.searchInputBox)}>
                    <Search
                        isLoading={isDestSearchLoading}
                        id="dest-search"
                        labelText="DROP"
                        onSearch={onDestSearchInput}
                        onSelectItem={onDestSelected}
                        options={destSearchOptions}
                        labelKey={destLabelKey}
                        selectedItem={dest}
                        searchText={destSearchText}
                        placeholder="Enter your unloading point"
                        minLength={0}
                        showClearOption={!!dest}
                        inputWrapperClassName={classNames(Styles.searchWrapper, "searchLocation")}
                        containerClassName={classNames(Styles.searchContainer, Styles.destSearchContainer)}
                        containerStyles={{
                            height: "60px",
                            borderRadius: "0px 10px 10px 0px"
                        }}
                        labelClassName={Styles.searchLocationLabel}
                    />
                </div>
            </div>
        </div>
    );
};

export default LocationSearch;
