import React, { useState } from "react";

// Components
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import FilterToggleButton from "../../desktop/filter-toggle-button/FilterToggleButton";
import LorryTypeFilterOptions, {
    LorryTypeFilterOptionsProps
} from "../lorry-type-filter-options/LorryTypeFilterOptions";

export interface LorryType {
    id: number;
    label: string;
    icon: string;
    value: string;
}

export interface LorryTypeFilterProps extends LorryTypeFilterOptionsProps {
    className?: string;
    onToggle?: () => void;
}

const LorryTypeFilter = ({
    selectedLorryType,
    handleSelectedLorryType,
    className,
    onToggle,
    ...props
}: LorryTypeFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleLorryFilterToggle = () => {
        setIsOpen((state) => !state);
        onToggle?.();
    };

    const _onSelect: LorryTypeFilterOptionsProps["handleSelectedLorryType"] = (val) => {
        handleSelectedLorryType(val);
        setIsOpen(false);
    };

    return (
        <Dropdown
            isOpen={isOpen}
            DropdownToggleBtn={
                <FilterToggleButton
                    type="vehicle"
                    label={selectedLorryType?.label || "Vehicle Type"}
                    icon={selectedLorryType?.icon}
                    isOpen={isOpen}
                />
            }
            handleToggle={handleLorryFilterToggle}
            containerClassName={className}
        >
            <LorryTypeFilterOptions
                selectedLorryType={selectedLorryType}
                handleSelectedLorryType={_onSelect}
                {...props}
            />
        </Dropdown>
    );
};

export default LorryTypeFilter;
