import React from "react";
import classnames from "classnames";

import Styles from "./SectionWrapper.module.scss";

interface SectionWrapperProps {
    children: React.ReactNode;
    className?: string;
}

const SectionWrapper = ({ children, className }: SectionWrapperProps) => {
    return <div className={classnames(Styles.main, className)}>{children}</div>;
};

export default SectionWrapper;
