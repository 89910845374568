import classNames from "classnames";
import React, { ReactNode } from "react";
import SectionWrapper from "../../transport-nagar/common/section-wrapper/SectionWrapper";
import ContentSection from "../content/ContentSection";
import FaqSection from "../content/FaqSection";

interface Props {
    header: ReactNode;
    marketList: ReactNode;
    className?: string;
    VahakBenefitSlot?: ReactNode;
    content?: any;
    faqList?: any;
}

const Desktop = ({ header, marketList, className, VahakBenefitSlot, content, faqList }: Props) => {
    return (
        <main className={classNames(className)}>
            {header}
            <section>{marketList}</section>
            <SectionWrapper>{VahakBenefitSlot}</SectionWrapper>
            {content && (
                <SectionWrapper>
                    <ContentSection content={content} />
                </SectionWrapper>
            )}
            {faqList && (
                <SectionWrapper>
                    <FaqSection faqList={faqList} />
                </SectionWrapper>
            )}
        </main>
    );
};

export default Desktop;
