// libraries
import classnames from "classnames";
import React from "react";

// constants
import { MINIMUM_QUANTITY, MINIMUM_QUANTITY_LIT } from "@vahak/core/dist/constants/minimumQuantity";
import QuickFilterPill from "@vahak/core/dist/components/quick-filter-pill/QuickFilterPill";
// components
import ResponsiveContainer from "@vahak/core/dist/components/responsive-container/ResponsiveContainer";
// styles
import Styles from "./QuantityFilterOptions.module.scss";

export interface QuantityFilterOptionsProps {
    showClearBtn?: boolean;
    showLitres: boolean;
    handleSelectedQuantity: (value: typeof MINIMUM_QUANTITY[0] | undefined) => void;
    selectedQty: typeof MINIMUM_QUANTITY[0] | undefined;
}

const QuantityFilterOptions = ({
    showClearBtn,
    showLitres,
    handleSelectedQuantity,
    selectedQty
}: QuantityFilterOptionsProps) => {
    const _onSelectQty = (quantity: typeof MINIMUM_QUANTITY[0]) => {
        // Check if selected
        const selectedQuantity = selectedQty?.id === quantity.id ? undefined : quantity;
        // setSelectedQty(selectedQuantity);
        handleSelectedQuantity(selectedQuantity);
    };

    // Clear selected filter
    const onClickClear = () => {
        // setSelectedQty(undefined);
        handleSelectedQuantity(undefined);
    };

    return (
        <div className={classnames(Styles.quantityFilterWrapper)}>
            <div className={Styles.header}>
                <h3>Minimum quantity</h3>
                {showClearBtn && (
                    <span className={Styles.clearFilter} onClick={onClickClear}>
                        Clear
                    </span>
                )}
            </div>
            <ResponsiveContainer className={classnames(Styles.quantityCardWrapper)}>
                {(showLitres ? MINIMUM_QUANTITY_LIT : MINIMUM_QUANTITY).map((qty) => (
                    <QuickFilterPill
                        onSelected={_onSelectQty}
                        onDeselected={_onSelectQty}
                        className={classnames(
                            Styles.minimumQuantityCheck,
                            selectedQty?.id === qty.id && Styles.selected
                        )}
                        key={qty.id}
                        text={qty.label}
                        data={qty}
                        isSelected={selectedQty?.id === qty.id}
                    />
                ))}
            </ResponsiveContainer>
        </div>
    );
};

export default QuantityFilterOptions;
