import classNames from "classnames";
import React, { ReactNode } from "react";
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";
import Styles from "./FilterToggleButton.module.scss";
import VehicleCapacity from "@vahak/core/dist/icons/nf_vehicle_capacity_grey.svg";
import VehicleType from "@vahak/core/dist/icons/nf_vehicle_type_grey.svg";
import CalendarGrey from "@vahak/core/dist/icons/CalendarGrey.svg";
import AppImg from "../../../../components/utils/AppImg";
import { getImageNameFromURL } from "../../../../utils/getImageNameFromURL";

interface FilterToggleButtonProps {
    label: ReactNode;
    //todo
    icon?: any;
    type?: string;
    isOpen?: boolean;
    onClick?: () => void;
    className?: string;
}

const FilterToggleButton = ({ label, icon, type, isOpen, onClick, className }: FilterToggleButtonProps) => {
    return (
        <div className={classNames(Styles.mainWrapper)}>
            <AppBtn
                className={classNames(Styles.toggleBtn, isOpen && Styles.isOpen, icon && Styles.isActive, className)}
                type="primary"
                btnType="button"
                onClick={onClick}
            >
                <div className={classNames(Styles.buttonWrapper)}>
                    <div className={classNames(Styles.icon)}>
                        {type === "quantity" && <VehicleCapacity />}
                        {label === "Vehicle Type" && !icon ? (
                            <VehicleType />
                        ) : icon && type === "vehicle" ? (
                            <AppImg src={getImageNameFromURL(icon)} alt={label?.toString()} width="40" height="28" />
                        ) : (
                            ""
                        )}
                        {type === "date" && <CalendarGrey />}
                    </div>
                    <span>{label}</span>
                </div>
            </AppBtn>
        </div>
    );
};

export default FilterToggleButton;
