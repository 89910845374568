import { useState, useEffect, useRef } from "react";

const useIntersect = ({ root = null, rootMargin = "0px", threshold = 0 }: IntersectionObserverInit) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { root, rootMargin, threshold }
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [root, rootMargin, threshold]);

    return { targetRef, isIntersecting };
};

export default useIntersect;
