import { IAgentDetailResponse, ICallingDisposition } from "@vahak/core/dist/custom-types/common-preloads";
import { BidInfo, BidsSummary } from "../useGetLoadAllDetailsV2/type";
import { LoadTicketType } from "../../../components/admin/ticket-system-helper/constants";

export interface LoadMarketType {
    data: Data;
}

export interface Data {
    load_cards: LoadMarketCard[];
    total_pages: number;
    total_records: number;
    page_number: number;
    has_next?: boolean;
    load_aggregation_response: LoadAggregationResponse;
}

export interface IAssignedToDetailsResponse {
    assigned_to_details?: IAssignedAgentDetail[];
}

export interface IAssignedAgentDetail {
    name?: string;
    ticket_type?: LoadTicketType;
    assigned_at?: string;
}

export interface ITicketInfo {
    ticket_info?: { pending_tickets_count?: number };
}

export interface LoadMarketCard extends IAssignedToDetailsResponse, ITicketInfo, IAgentDetailResponse {
    load_detail: LoadMarketDetailsV2;
    company_detail: CompanyDetail;
    minimum_bid_info: MinimumBidInfo;
    relevancy_tags: RelevancyTags;
    bid_infos?: BidInfo[];
    bids_summary?: BidsSummary;
}

export interface CompanyDetail {
    id: number;
    name: string;
    logo: string;
    service_type: string;
    ratings: number;
    user_app_version: string;
    is_company_verified: boolean;
    is_aadhaar_verified: boolean;
    is_gst_verified: boolean;
    is_bank_verified: boolean;
    is_member: boolean;
    home_city_name: string;
    phone_number?: string;
}

export interface LoadMarketDetailsV2 {
    id: number;
    company_id: number;
    status: number;
    status_text: string;
    source: string;
    source_id: number;
    destination: string;
    destination_id: number;
    posted_time: string;
    expiration_hour: string;
    quantity: number;
    length: number;
    breadth: number;
    height: number;
    is_odc: boolean;
    remarks: string;
    payment_type: number;
    material_type: string;
    lorry_type: string;
    lorry_type_id: number;
    total_response: number;
    available_for: string;
    amount: number;
    expected_amount: number;
    expected_type: number;
    is_lr_uploaded: boolean;
    eway_status: number;
    eway_bill_image: string;
    rc_status: number;
    rc_document: string;
    loading_timestamp: number;
    date_of_loading: string;
    report_time: string;
    number_of_vehicles: number;
    bulk_id: number;
    is_verified: boolean;
    advance_payment_percentage: number;
    updated_at: string;
    load_address: LoadAddress;
    distance: number;
    source_locality_name: string;
    destination_locality_name: string;
    source_city_name: string;
    source_city_id: number;
    destination_city_name: string;
    destination_city_id: number;
    source_state_name: string;
    source_state_id: number;
    destination_state_name: string;
    destination_state_id: number;
    price_per_tonne_per_km: number;
    body_type: number;
    total_tyres: number;
    size: number;
    size_text: string;
    calling_disposition: ICallingDisposition;
    is_load_address_present: boolean;
    insurance_details: InsuranceDetails;
    is_insurance_supported: boolean;
    is_insured: boolean;
    is_tap_n_go: boolean;
    is_spot_load: boolean;
    meta_data?: LoadMetaData;
}

export interface LoadMetaData {
    user_feedback?: string;
    limited_bids?: number;
    is_verification_amount_paid?: boolean;
    verification_amount?: number;
    cancel_ticket_status?: number;
    is_load_in_top_routes?: boolean;
    loading_charges_detail?: LoadingChargesDetail;
    preferred_amounts?: PreferredAmount[];
}

export interface PreferredAmount {
    company_id?: number;
    amount?: number;
    name?: string;
    phone_number?: string;
}
interface LoadingChargesDetail {
    is_applicable?: boolean;
    charges?: number;
}
export interface InsuranceDetails {
    sum_insured: number;
    invoice_value: number;
    premium_amt: number;
    date_of_purchase: number;
    is_load_details_filled: boolean;
}

export interface LoadAddress {
    load_id: number;
    company_id: number;
    pickup_employee_id: number;
    drop_employee_id: number;
    pickup_address: string;
    drop_address: string;
    loading_timestamp: number;
    pickup_landmark: string;
    drop_landmark: string;
    pickup_info: PInfo;
    drop_info: PInfo;
}

export interface PInfo {
    address: string;
    landmark: string;
    employee_id: number;
    employee_name: string;
    employee_phone_number: string;
}

export interface MinimumBidInfo {
    amount: number;
    load_id: number;
    id: number;
}

export interface RelevancyTags {
    from_connection: boolean;
    matching_with_your_load: boolean;
    matching_with_your_lorry: boolean;
    nearby_home_city: boolean;
    returning_towards_your_home_city: boolean;
    past_interaction: boolean;
    tags: string[] | null;
}

export interface LoadAggregationResponse {
    lorry_type_id: LorryTypeID[];
    source_city_id_destination_city_id_lorry_type_id_quantity_material_type: null;
}

export interface LorryTypeID {
    count: number;
    lorry_type_id: number;
}

/**
 * Payload Types
 */
export interface LoadMarketParam {
    page?: number;
    c_i?: string;
    lorry_type_id?: number;
    city_id?: number;
    state_id?: number;
    destination_city_id?: number;
    destination_state_id?: number;
    source_place_id?: string;
    destination_place_id?: string;
    quantity_string?: string;
    l?: number;
    lorry_type_counts?: boolean;
    loadId?: number[];
    preloads?: (
        | "load_details"
        | "agent_details"
        | "company_detail"
        | "minimum_bid_info"
        | "bid_infos"
        | "relevancy_tags"
    )[];

    /**
     * Internal use
     */
    isAdmin?: boolean;

    /**
     * server side use
     */
    origin?: string;
    tap_n_go_filter?: number;
}

export enum TapNGoLoadFilter {
    ALL,
    TRUE,
    FALSE
}
