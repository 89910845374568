import classNames from "classnames";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { AppContext } from "@vahak/core/dist/app-context";
import { CitySearchResultWithCateg } from "@vahak/core/dist/custom-types/location-search";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";

import useIntersect from "../../../../hooks/useIntersect";
import { usePlaceSearch } from "@vahak/core/dist/hooks/useSearch";
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";
import SearchFullScreenMobile from "@vahak/core/dist/components/search-full-screen-mobile/SearchFullScreenMobile";
import { Filters, HeaderProps } from "../../../marketplace/common/types";
import MobileFilters, { MobileFiltersProps } from "../mobile-filters/MobileFilters";
import BlueRightIcon from "@vahak/core/dist/icons/nf_rightArrowBlue.svg";
import FilterIcon from "@vahak/core/dist/icons/nf_filter_blue.svg";

import Styles from "./MobileHeader.module.scss";
import { useToggleModal } from "../../../../hooks/useToggleModal";
import { useRouter } from "next/router";
import {
    DEST_SEARCH_VISIBLE,
    MOBILE_FILTERS_MARKETPLACE,
    SRC_SEARCH_VISIBLE
} from "../../../../constants/modal-strings";
import { useTranslation } from "next-i18next";
import ChangeServiceType from "../../common/change-service-type/ChangeServiceType";
import { useFeatureByServiceType } from "../../../../hooks/useFeatureByServiceType";
import { ADMIN_OPERATION } from "../../../../constants/adminOperation";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";

export interface MobileHeaderRef {
    src: CitySearchResultWithCateg | undefined;
    dest: CitySearchResultWithCateg | undefined;
    filters: Filters | undefined;
}
interface MobileHeaderProps extends HeaderProps {}

const MobileHeader = forwardRef<MobileHeaderRef, MobileHeaderProps>(
    ({ mktType, onChangeMktType, src, dest, onSelectDest, onSelectSrc, filters, onApplyFilters }, forwardedRef) => {
        const { t } = useTranslation(["common"]);
        const { checkPermission } = useCsDashboard();
        const { sendGAandMoEngageEvent } = useEventTrackerService();
        const isTso = checkPermission(ADMIN_OPERATION.tagAgent);
        const { recentTransporterCitySearches, isPhoneVerified, companyId, csDashboardPermission } =
            useContext(AppContext);

        const isLoadMkt = mktType === "load";

        const trackingEventAutoCalculatedData = {
            source_screen: isLoadMkt ? "load_market" : "lorry_market",
            filter: isLoadMkt ? "load_market" : "lorry_market",
            from_city: src?.name,
            from_state: src?.state,
            to_city: dest?.name,
            to_state: dest?.state,
            filter_selection: [
                ...(filters?.qty ? [filters?.qty?.label] : []),
                ...(filters?.lorryType ? [filters?.lorryType?.label] : [])
            ],
            ...(isTso && {
                source_screen: isLoadMkt ? "tso_load_market" : "tso_lorry_market"
            })
        };
        const trackClick = (name: string, additionalData: GenericObject = {}) => {
            const data = {
                ...trackingEventAutoCalculatedData,
                ...additionalData
            };

            sendGAandMoEngageEvent({
                name,
                data
            });
        };

        const { openModal: OpenFilterScreenModal, closeModal: CloseFilterScreenModal } =
            useToggleModal(MOBILE_FILTERS_MARKETPLACE);
        const { openModal: OpenSrcSearchVisible, closeModal: CloseSrcSearchVisible } =
            useToggleModal(SRC_SEARCH_VISIBLE);
        const { openModal: OpenDestSearchVisible, closeModal: CloseDestSearchVisible } =
            useToggleModal(DEST_SEARCH_VISIBLE);

        const { targetRef, isIntersecting } = useIntersect({
            threshold: 0
        });

        const [showStickyHeader, setShowStickyHeader] = useState(false);
        const router = useRouter();
        useEffect(() => {
            setShowStickyHeader(!isIntersecting);
        }, [isIntersecting]);

        const _onChangeMktType: React.MouseEventHandler<HTMLLIElement> = (e) => {
            onChangeMktType((e.target as HTMLLIElement).dataset?.val as MarketType);
        };

        const {
            isSearchLoading: isSrcSearchLoading,
            labelKey: srcLabelKey,
            onItemSelected: onSrcSelected,
            onSearchInput: onSrcSearchInput,
            searchOptions: srcSearchOptions,
            onFocus: onSrcSearchFocus,
            searchText: srcSearchText
        } = usePlaceSearch({
            locationParamsOption: {
                allowState: true,
                source: isLoadMkt
                    ? LocationSearchSource.LoadMarketPlaceSearchSource
                    : LocationSearchSource.LorryMarketPlaceSearchSource
            },
            showCurrentLocationOption: true,
            defaultOptions: recentTransporterCitySearches,
            onSelectItem: async (selectedItems: any) => {
                CloseSrcSearchVisible();
                onSelectSrc(selectedItems[0]);
            }
        });

        const {
            isSearchLoading: isDestSearchLoading,
            labelKey: destLabelKey,
            onItemSelected: onDestSelected,
            onSearchInput: onDestSearchInput,
            onFocus: onDestSearchFocus,
            searchOptions: destSearchOptions,
            searchText: destSearchText
        } = usePlaceSearch({
            locationParamsOption: {
                allowState: true,
                source: isLoadMkt
                    ? LocationSearchSource.LoadMarketPlaceSearchDestination
                    : LocationSearchSource.LorryMarketPlaceSearchDestination
            },
            showCurrentLocationOption: true,
            defaultOptions: recentTransporterCitySearches,
            onSelectItem: async (selectedItems: any) => {
                CloseDestSearchVisible();
                onSelectDest(selectedItems[0]);
            }
        });

        const onClickApplyFilter: MobileFiltersProps["onApplyFilter"] = async (_filters) => {
            // history.goBack();
            // await sleep(10);
            CloseFilterScreenModal();
            onApplyFilters(_filters);
        };

        const onClickSrcSearch = () => {
            // OpenSrcSearchVisible();
            OpenSrcSearchVisible();
        };

        const onClickDestSearch = () => {
            // OpenDestSearchVisible();
            OpenDestSearchVisible();
        };

        const { showLoadAndLorryFeatures } = useFeatureByServiceType();

        return (
            <>
                <section className={Styles.nonStickyHeader} ref={targetRef}>
                    {showLoadAndLorryFeatures ? (
                        <div className={Styles.loadLorryBtnWrap}>
                            <ul>
                                <li
                                    data-val="load"
                                    className={mktType === "load" ? Styles.selected : ""}
                                    onClick={_onChangeMktType}
                                >
                                    {t("common:live_load_market")}
                                </li>
                                <li
                                    data-val="lorry"
                                    className={mktType === "lorry" ? Styles.selected : ""}
                                    onClick={_onChangeMktType}
                                >
                                    {t("common:live_lorry_market")}
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="container">
                        <div className={Styles.searchBox}>
                            <div className={Styles.pick} onClick={onClickSrcSearch}>
                                <div className={Styles.searchVal}>
                                    <span className={Styles.label}>PICKUP</span>
                                    <h3 className={classNames(Styles.city, !src && Styles.placeholder)}>
                                        {src ? `${src.name}` : "Search Loading City"}
                                    </h3>
                                </div>
                            </div>
                            <div className={Styles.drop} onClick={onClickDestSearch}>
                                <div className={Styles.searchVal}>
                                    <span className={Styles.label}>DROP</span>
                                    <h3 className={classNames(Styles.city, !dest && Styles.placeholder)}>
                                        {dest ? `${dest.name}` : "Search Unloading City"}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className={Styles.filterSavedBox}>
                            <div className={Styles.filterButtonWrapper}>
                                <AppBtn
                                    className={Styles.filterBtn}
                                    onClick={() => {
                                        OpenFilterScreenModal();
                                        trackClick(GA4EventNames.MARKET_PLACE["filters_clicked"], {});
                                    }}
                                >
                                    <span className={Styles.icon}>
                                        {" "}
                                        <FilterIcon />
                                    </span>
                                    Filters{" "}
                                    {filters &&
                                        `(${
                                            (filters.lorryType ? 1 : 0) +
                                            (filters.qty ? 1 : 0) +
                                            (filters?.isAssuredLoad ? 1 : 0)
                                        })`}
                                    {(filters?.lorryType || filters?.qty || filters?.isAssuredLoad) && (
                                        <span className={Styles.dot}></span>
                                    )}
                                </AppBtn>
                            </div>
                            {!(isPhoneVerified && companyId) ? <ChangeServiceType /> : <></>}
                        </div>
                    </div>
                </section>

                {showStickyHeader ? (
                    <section
                        className={classNames(
                            Styles.stickyHeader,
                            isPhoneVerified && !companyId ? Styles.companyNotRegistered : "",
                            showStickyHeader ? Styles.stickyHeaderVisible : Styles.stickyHeaderHidden,
                            csDashboardPermission?.includes(ADMIN_OPERATION.tagAgent) ? Styles.headerSpacingForTSO : ""
                        )}
                    >
                        <div className={Styles.contentBox}>
                            <div className="container">
                                <div className={classNames(Styles.filterRow, Styles.filterSavedBox)}>
                                    <div className={Styles.colSearch}>
                                        <div className={classNames(Styles.src)} onClick={onClickSrcSearch}>
                                            <h3 className={classNames(Styles.city, !src && Styles.placeholder)}>
                                                {src ? `${src.name}` : "Loading City"}
                                            </h3>
                                            <BlueRightIcon />
                                        </div>
                                        <div className={Styles.dest} onClick={onClickDestSearch}>
                                            <h3 className={classNames(Styles.city, !dest && Styles.placeholder)}>
                                                {dest ? `${dest.name}` : "Unloading City"}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className={Styles.colFilter}>
                                        <AppBtn className={Styles.filterBtn} onClick={() => OpenFilterScreenModal()}>
                                            <span className={Styles.icon}>
                                                <FilterIcon />
                                            </span>
                                            {(filters?.lorryType || filters?.qty) && (
                                                <span className={Styles.dot}></span>
                                            )}
                                        </AppBtn>
                                    </div>
                                </div>
                                {!(isPhoneVerified && companyId) ? (
                                    <div className={Styles.changeServiceTypeWrapperStickyHeader}>
                                        <ChangeServiceType />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </section>
                ) : (
                    <></>
                )}

                <SearchFullScreenMobile
                    isLoading={isSrcSearchLoading}
                    id="src-search"
                    onSearch={onSrcSearchInput}
                    onSelectItem={onSrcSelected}
                    onFocus={onSrcSearchFocus}
                    options={srcSearchOptions}
                    labelKey={srcLabelKey}
                    selectedItem={src}
                    searchText={srcSearchText}
                    isVisible={!!router.asPath.includes(SRC_SEARCH_VISIBLE)}
                    onClickBackBtn={() => CloseSrcSearchVisible()}
                    placeholder="Enter pickup city"
                    showClearOption={!!src}
                />
                <SearchFullScreenMobile
                    isLoading={isDestSearchLoading}
                    id="dest-search"
                    onSearch={onDestSearchInput}
                    onSelectItem={onDestSelected}
                    onFocus={onDestSearchFocus}
                    options={destSearchOptions}
                    labelKey={destLabelKey}
                    selectedItem={dest}
                    searchText={destSearchText}
                    isVisible={!!router.asPath.includes(DEST_SEARCH_VISIBLE)}
                    onClickBackBtn={() => CloseDestSearchVisible()}
                    placeholder="Enter drop city"
                    showClearOption={!!dest}
                />
                {!!router.asPath.includes(MOBILE_FILTERS_MARKETPLACE) && (
                    <MobileFilters
                        selectedFilters={filters}
                        onApplyFilter={onClickApplyFilter}
                        onClickClose={() => CloseFilterScreenModal()}
                    />
                )}
            </>
        );
    }
);

export default MobileHeader;
