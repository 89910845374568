import classNames from "classnames";
import React, { CSSProperties, ReactNode } from "react";
import Styles from "./DropdownToggleButton.module.scss";

interface DropdownTogglerProps {
    handleClick?: () => void;
    label: string;
    subText: string;
    icon?: ReactNode;
    buttonContentContainerStyle?: CSSProperties;
    btnStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    subTextStyle?: CSSProperties;
    isHover?: boolean;
    isFilterSelected?: boolean;
    isOpen?: boolean;
}

const DropdownToggleButton = ({
    handleClick,
    label,
    subText,
    icon,
    buttonContentContainerStyle,
    btnStyle,
    labelStyle,
    subTextStyle,
    isHover = true,
    isFilterSelected,
    isOpen
}: DropdownTogglerProps) => {
    return (
        <div className={classNames(Styles.toggleWrapper)}>
            <div className={classNames(Styles.toggleContainer)}>
                <div
                    className={classNames(
                        Styles.filterButton,
                        `${
                            isOpen || isFilterSelected
                                ? isFilterSelected
                                    ? Styles.isFilterSelected
                                    : Styles.isOpen
                                : ""
                        } ${!isFilterSelected && !isOpen && isHover ? Styles.isHover : ""}`
                    )}
                    style={{ ...btnStyle }}
                >
                    <div className={classNames(Styles.toggleButtonContent)} style={{ ...buttonContentContainerStyle }}>
                        <h5 className={classNames(Styles.label, "label")} style={{ ...labelStyle }}>
                            {label}
                        </h5>
                        <h5 className={classNames(Styles.subText, "subText")} style={{ ...subTextStyle }}>
                            {subText}
                            {"  "}
                            {icon && icon}
                        </h5>
                    </div>
                </div>
                {/* </AppBtn> */}
            </div>
        </div>
    );
};

export default DropdownToggleButton;
