import React, { useContext, useEffect, useRef, useState, MouseEvent } from "react";
import dynamic from "next/dynamic";
import { AppContext, RestrictedEvents } from "@vahak/core/dist/app-context";
import { CitySearchResultWithCateg, PlaceSearchResult } from "@vahak/core/dist/custom-types/location-search";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";
import Desktop from "./desktop/Desktop";
import Mobile from "./mobile/Mobile";
import { capitalize } from "@vahak/core/dist/methods/capitalize";
import { getMinMaxQtyObj } from "@vahak/core/dist/methods/getMinMaxQty";
import { getPageLoadSourceUTMParam, marketplaceUtmParam } from "@vahak/core/dist/methods/getPageLoadSourceUTMParam";
import Styles from "./Marketplace.module.scss";
import DesktopHeader from "./desktop/desktop-header/DesktopHeader";
import { Filters, HeaderProps } from "./common/types";

//Component
import { IMG_BASE_URL } from "@vahak/core/dist/constants/misc";

import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { STATE_LIST } from "@vahak/core/dist/constants/StateList";
import { EXPECTED_AMOUNT_TYPE, PAYMENT_TYPE_MAP } from "@vahak/core/dist/constants/payment";

import { IDs } from "@vahak/core/dist/constants/automation";

import { LORRY_CATEGORIES_ENUM, NEW_LORRY_TYPES, TRUCK_BODY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import { MARKET_TYPE } from "@vahak/core/dist/constants/market";
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";

import classNames from "classnames";
import { toast } from "@vahak/core/dist/components/toast/toast";
import { useRouter } from "next/router";
import MobileHeader from "./mobile/mobile-header/MobileHeader";
import { getCurrentState } from "@vahak/core/dist/methods/getCurrentState";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
// import EventScriptOnLoad from "../../utils/EventScriptOnLoad";

import useToggleModalWithLocalState from "@vahak/core/dist/hooks/useToggleModalWithLocalState";
import { breadcrumbsConstants } from "../../constants/breadcrumbs-constants";
import PageMetaTags from "../common/page-meta-tags/PageMetaTags";
import useValidateUserStatus from "../../hooks/useValidateUserStatus";
import MandateServiceType from "./mandate-service-type/MandateServiceType";
import { LoadMarketCard, LoadMarketType, TapNGoLoadFilter } from "../../services/load/useGetLoadMarketV2/type";
import { useGetLoadMarketV2 } from "../../services/load/useGetLoadMarketV2/useGetLoadMarketV2";
import { BiddingFormContext, BID_ACTIONS } from "../../BiddingFormContextProvider";
import { LORRY_POSTING_FORM_LOCATION_STATE } from "../posting/lorry-posting/helpers/constants";
import { LOAD_POSTING_FORM_LOCATION_STATE } from "../posting/load-posting/helpers/constants";
import { useGetLorryMarketV2 } from "../../services/lorry/useGetLorryMarketV2/useGetLorryMarketV2";
import { LorryMarketCard, LorryMarketType } from "../../services/lorry/useGetLorryMarketV2/type";
import { stringOperations } from "@vahak/core-ui/dist/methods/string";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../constants/adminOperation";
import { TAG_AGENT_LOAD_PAGE } from "../../constants/route-constants";
import { useGetCurrentLocationOnPageLoad } from "@vahak/core/dist/hooks/useGetCurrentLocation";
import ShowMoreText from "@vahak/core-ui/dist/components/ShowMoreText";
import Typography from "@vahak/core-ui/dist/components/Typography";
import { getMktContentByCity } from "./content/content";
import VahakBenefits from "../vahak-benefits/VahakBenefits";
import { LocationSearchSource, searchUsingPlacesAPI } from "@vahak/core/dist/_services/city-search.service";
import ListContainer from "./common/list-container/ListContainer";
import { useGetLoadMarketInfiniteQueryV2 } from "../../services/load/useGetLoadMarketV2/useGetLoadMarketInfiniteQueryV2";
import { useGetLorryMarketInfiniteQueryV2 } from "../../services/lorry/useGetLorryMarketV2/useGetLorryMarketInfiniteQueryV2";
import MarketplaceLoadCard from "../marketplace-new/load-card/MarketplaceLoadCard";
import MarketplaceLorryCard from "../marketplace-new/lorry-card/MarketplaceLorryCard";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../context/load-lorry-posting";

const NoActiveLoadsLorries = dynamic(() => import("../common/not-found/no-active-loads-lorries/NoActiveLoadsLorries"), {
    ssr: false
});

const LorryBidForm = dynamic(() => import("./common/forms/lorry-bid-form/LorryBidForm"), {
    ssr: false
});
const LoadBidFormNew = dynamic(() => import("./load-bid-form-new/LoadBidFormNew"), {
    ssr: false
});

export interface MarketplaceProps {
    marketplaceType: string;
    isLoggedIn: boolean;
    isPhoneVerified: boolean | undefined;
    urlSrc: string;
    urlDest: string;
    urlFilters: Filters | null;
    urlPage: number | null;
    serviceType: CompanyServiceType | null;
    route?: string;
    isBot?: boolean;
}

let shouldOpenBidFormOnPageLoad = false;

export const getLoadTrackData = (ld: LoadMarketCard) => {
    const { load_detail, company_detail } = ld;
    const requiredLorry = NEW_LORRY_TYPES.find(({ id }) => id === load_detail?.lorry_type_id);

    const loadInfo = {
        load_id: load_detail?.id,
        is_load_verified: load_detail?.is_verified,
        load_type: load_detail?.is_tap_n_go ? "tap_n_go" : "general",
        from_location: [load_detail?.source_city_name, load_detail?.source_state_name].join(","),
        to_location: [load_detail?.destination_city_name, load_detail?.destination_state_name].join(","),
        material: load_detail?.material_type,
        quantity: load_detail?.quantity,
        quantity_type: LORRY_CATEGORIES_ENUM.TANKER === load_detail?.lorry_type_id ? "kilo_liters" : "tonnes",
        required_vehicle_type: requiredLorry?.label,
        required_body_type: load_detail?.body_type === TRUCK_BODY_TYPES.CLOSED ? "open_full_body" : "open_half_body",
        required_tyres: load_detail?.total_tyres,
        loading_time: load_detail?.loading_timestamp,
        recommended_price: null,
        expected_price: load_detail?.expected_amount,
        expected_price_type: stringOperations(EXPECTED_AMOUNT_TYPE[load_detail?.expected_type], [
            "toLowerCase",
            "replaceSpacesWithUnderscore"
        ]),
        payment_type: stringOperations(PAYMENT_TYPE_MAP[load_detail?.payment_type], [
            "toLowerCase",
            "replaceSpacesWithUnderscore"
        ]),
        advance_percentage: load_detail?.advance_payment_percentage,
        odc: load_detail?.is_odc,
        odc_length: load_detail?.length,
        odc_breadth: load_detail?.breadth,
        odc_height: load_detail?.height
    };

    const profileInfo = {
        profile_user_id: null,
        profile_user_name: null,
        profile_company_id: company_detail?.id,
        profile_company_name: company_detail?.name,
        profile_phone_number: null,
        profile_service_type: company_detail?.service_type,
        profile_location: company_detail?.home_city_name,
        total_connections: null
    };
    return {
        ...loadInfo,
        ...profileInfo
    };
};

export const getLorryTrackData = (lr: LorryMarketCard) => {
    const { lorry_detail, company_detail } = lr;

    const isAip = lorry_detail?.total_routes === STATE_LIST.length;
    const routes = isAip
        ? "All India Permit"
        : STATE_LIST.filter((state) => lorry_detail?.routes?.some(({ id }) => id === state.id))?.map(
              (state) => state.code
          );
    const lorryInfo = {
        lorry_id: lorry_detail?.id,
        is_lorry_verified: lorry_detail?.is_verified,
        vehicle_number: lorry_detail?.number,
        current_location: [lorry_detail?.source_info.city_name, lorry_detail?.source_info.state_name].join(","),
        routes: routes,
        total_route: lorry_detail?.total_routes,
        lorry_type: lorry_detail?.lorry_type,
        lorry_capacity: lorry_detail?.capacity,
        capacity_type: LORRY_CATEGORIES_ENUM.TANKER === lorry_detail?.type_value ? "kilo_liters" : "tonnes",
        total_tyres: lorry_detail?.total_tyres,
        body_type: lorry_detail?.body_type === TRUCK_BODY_TYPES.CLOSED ? "open_full_body" : "open_half_body",
        vehicle_size: lorry_detail?.size,
        vehicle_size_type: lorry_detail?.size_text
    };

    const profileInfo = {
        profile_user_id: null,
        profile_user_name: null,
        profile_company_id: company_detail?.id,
        profile_company_name: company_detail?.name,
        profile_phone_number: null,
        profile_service_type: company_detail?.service_type,
        profile_location: company_detail?.home_city_name,
        total_connections: null
    };
    return {
        ...lorryInfo,
        ...profileInfo
    };
};

const MarketPlace = ({
    marketplaceType,
    isLoggedIn: initialIsLoggedInState,
    urlSrc,
    urlDest,
    urlFilters,
    isPhoneVerified: initialPhoneVerifiedState,
    serviceType: initialServiceType,
    route = "",
    isBot
}: MarketplaceProps) => {
    //router
    const { query } = useRouter();
    const router = useRouter();
    const loadLorryPostingConfig = useLoadLorryPosting();

    /***
     * States
     */
    const [activeMarket, setActiveMarket] = useState<MarketType>(
        (query.params?.[0] as MarketType) || (marketplaceType as MarketType)
    );

    const [src, setSrc] = useState<CitySearchResultWithCateg>();
    const [dest, setDest] = useState<CitySearchResultWithCateg>();
    const [filters, setFilters] = useState<Filters>();
    const [shouldExecuteApi, setShouldExecuteApi] = useState(false);
    const [selectedLoad, setSelectedLoad] = useState<LoadMarketCard>();
    const [selectedLorry, setSelectedLorry] = useState<LorryMarketCard>();
    const [shouldFetchCurrentLocationOnPageLoad, setShouldFetchCurrentLocationOnPageLoad] = useState(false);
    const [loadData, setLoadData] = useState<LoadMarketType["data"]["load_cards"]>(Array(12).fill(undefined));
    const [lorryData, setLorryData] = useState<LorryMarketType["data"]["lorry_cards"]>(Array(12).fill(undefined));
    const [lorryTotalPage, setLorryMarketplaceTotalPage] = useState(0);
    const [loadTotalPage, setLoadMarketplaceTotalPage] = useState(0);
    const [lorryTotalRecords, setLorryMarketplaceTotalRecords] = useState(0);
    const [loadTotalRecords, setLoadMarketplaceTotalRecords] = useState(0);

    /***
     * Context
     */
    const {
        isMobile,
        companyId,
        serviceType,
        customerCompanyId,
        isPhoneVerified,
        setShouldPreventLayoutSwitch,
        setServiceType,
        eventCounts,
        updateEventCount,
        setShowAppRedirectionPopup,
        shouldPreventLayoutSwitch
    } = useContext(AppContext);

    const { checkPermission, isCsDashboardUser } = useCsDashboard();
    const isTso = checkPermission(ADMIN_OPERATION.tagAgent);

    const { bidFormConfig, dispatchBid } = useContext(BiddingFormContext);
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(isPhoneVerified && companyId));

    const currLoc = useGetCurrentLocationOnPageLoad({
        enabled: shouldFetchCurrentLocationOnPageLoad,
        locationSearchParams: {
            source: LocationSearchSource.CompanyCurrentLocation
        }
    });
    let eventTypeSrcDestSelectClickRef = useRef<"src" | "dest" | undefined>();
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const [trackingDataForSelectedLoad, setTrackingDataForSelectedLoad] = useState<{
        load: LoadMarketCard;
        index: number;
    }>();
    const [trackingDataForSelectedLorry, setTrackingDataForSelectedLorry] = useState<{
        lorry: LorryMarketCard;
        index: number;
    }>();

    const trackingEventAutoCalculatedData = {
        source_screen: activeMarket === "load" ? "load_market" : "lorry_market",
        filter: activeMarket === "load" ? "load_market" : "lorry_market",
        from_city: src?.name,
        from_state: src?.state,
        to_city: dest?.name,
        to_state: dest?.state,
        ...(isTso &&
            TAG_AGENT_LOAD_PAGE === route && {
                source_screen: activeMarket === "load" ? "tso_load_market" : "tso_lorry_market"
            })
    };

    const isLoadBid = Boolean(router.query["loadbid"]);
    const isLorryBid = Boolean(router.query["lorrybid"]);
    const loadLorryId = router.query["loadbid"] || router.query["lorrybid"];
    const [sharedLoad, setSharedLoad] = useState<LoadMarketCard>();
    const [sharedLorry, setSharedLorry] = useState<LorryMarketCard>();

    /**
     * VWO AB testing
     * dummy const
     */

    const handleCitySelectGA4Event = (result: 0 | 1) => {
        const eventType = eventTypeSrcDestSelectClickRef.current;
        if (!!eventType) {
            let eventName = GA4EventNames.marketplace_source_city_entered_web;
            let cityName = src?.name;
            let stateName = src?.state;

            if (eventType === "dest") {
                eventName = GA4EventNames.marketplace_destination_city_entered_web;
                cityName = dest?.name;
                stateName = dest?.state;
            }

            sendGAandMoEngageEvent(
                {
                    name: eventName,
                    data: {
                        from_load: activeMarket === "load" ? 1 : 0,
                        city_name: cityName,
                        state_name: stateName,
                        result
                    }
                },
                true,
                false
            );
        }
    };

    // Load marketplace data
    const {
        isFetching: isLoadFetching,
        hasNextPage: hasNextPageLoadList,
        fetchNextPage: fetchNextPageLoadList
    } = useGetLoadMarketInfiniteQueryV2(
        {
            source_place_id: src?.place_id,
            destination_place_id: dest?.place_id,
            lorry_type_id: filters?.lorryType?.id,
            quantity_string: filters?.qty ? JSON.stringify(getMinMaxQtyObj(filters?.qty)) : "",
            c_i: checkPermission(ADMIN_OPERATION.tagAgent) ? "" : String(customerCompanyId || companyId || ""),
            preloads: ["company_detail", "agent_details", "bid_infos", "relevancy_tags"],
            isAdmin: isCsDashboardUser,
            tap_n_go_filter: filters?.isAssuredLoad ? TapNGoLoadFilter.ALL : TapNGoLoadFilter.FALSE,
            l: 9
        },
        {
            onSuccess(data) {
                setLoadData(data[0].load_cards);
                setLoadMarketplaceTotalPage(data[0]?.total_pages);
                setLoadMarketplaceTotalRecords(data[0]?.total_records);
                handleCitySelectGA4Event(data[0]?.load_cards?.length ? 1 : 0);
                setShouldExecuteApi(false);
            },
            onError(err) {},
            enabled: activeMarket == "load" && shouldExecuteApi
        }
    );

    /**
     * Load marketplace data for single load
     * Shared load
     */
    useGetLoadMarketV2(
        {
            preloads: ["company_detail", "bid_infos"],
            isAdmin: Boolean(customerCompanyId),
            loadId: loadLorryId ? [Number(loadLorryId)] : undefined,
            tap_n_go_filter: filters?.isAssuredLoad ? TapNGoLoadFilter.ALL : TapNGoLoadFilter.FALSE
        },
        {
            onSuccess(data) {
                shouldOpenBidFormOnPageLoad = true;
                data.load_cards.length && setSharedLoad(data.load_cards[0]);
            },
            onError(err) {},
            retry: 0,
            refetchOnWindowFocus: false,
            enabled: isLoadBid && Boolean(loadLorryId)
        }
    );

    //Lorry marketplace data
    const {
        isFetching: isLorryFetching,
        hasNextPage: hasNextPageLorryList,
        fetchNextPage: fetchNextPageLorryList
    } = useGetLorryMarketInfiniteQueryV2(
        {
            source_place_id: src?.place_id,
            destination_place_id: dest?.place_id,
            lorry_type_id: filters?.lorryType?.id,
            capacity_string: filters?.qty ? JSON.stringify(getMinMaxQtyObj(filters?.qty)) : "",
            c_i: checkPermission(ADMIN_OPERATION.tagAgent) ? "" : String(customerCompanyId || companyId || ""),
            preloads: ["company_detail", "agent_details", "rcdata"],
            vehicle_verified_only: filters?.verifiedVehicle,
            isAdmin: isCsDashboardUser,
            l: 9
        },
        {
            onSuccess: (response) => {
                setLorryData(response[0].lorry_cards);
                setLorryMarketplaceTotalPage(response[0]?.total_pages);
                setLorryMarketplaceTotalRecords(response[0]?.total_records);
                handleCitySelectGA4Event(response[0]?.lorry_cards?.length ? 1 : 0);
                setShouldExecuteApi(false);
            },
            onError: () => {},
            enabled: activeMarket == "lorry" && shouldExecuteApi
        }
    );

    /**
     * Lorry marketplace data for single load
     * Shared lorry
     */
    useGetLorryMarketV2(
        {
            preloads: ["company_detail", "rcdata"],
            isAdmin: Boolean(customerCompanyId),
            lorryId: loadLorryId ? [Number(loadLorryId)] : undefined
        },
        {
            onSuccess(data) {
                shouldOpenBidFormOnPageLoad = true;
                data.lorry_cards.length && setSharedLorry(data.lorry_cards[0]);
            },
            onError(err) {},
            enabled: isLorryBid && Boolean(loadLorryId)
        }
    );

    /***
     * Component did mount
     */
    useEffect(() => {
        (async () => {
            urlFilters && setFilters(urlFilters);

            if (urlSrc) {
                try {
                    const options = await searchUsingPlacesAPI(urlSrc, {
                        allowState: true,
                        source:
                            activeMarket === "load"
                                ? LocationSearchSource.LoadMarketPlaceSearchSource
                                : LocationSearchSource.LorryMarketPlaceSearchSource
                    });
                    if (options.length !== 0) {
                        setSrc({
                            id: 1,
                            name: options[0].description,
                            state_id: 0,
                            state_code: "",
                            state: options[0].state,
                            place_id: options[0].place_id
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            }
            if (urlDest) {
                try {
                    const options = (await searchUsingPlacesAPI(urlDest, {
                        allowState: true,
                        source:
                            activeMarket === "load"
                                ? LocationSearchSource.LoadMarketPlaceSearchDestination
                                : LocationSearchSource.LorryMarketPlaceSearchDestination
                    })) as PlaceSearchResult[];
                    if (options.length !== 0) {
                        setDest({
                            id: 1,
                            name: options[0].description,
                            state_id: 0,
                            state_code: "",
                            state: options[0].state,
                            place_id: options[0].place_id
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            }
            if (loadLorryId) {
                shouldOpenBidFormOnPageLoad = true;
            }
            /**
             * Work around to get updated context values to check if the user is logged in or not
             */
            if (Boolean(initialPhoneVerifiedState && initialIsLoggedInState) && Boolean(isPhoneVerified && companyId)) {
                setIsLoggedIn(true);
            }
            if (!urlSrc && !urlDest && !isCsDashboardUser) {
                setShouldFetchCurrentLocationOnPageLoad(true);
            } else navigate();
        })();
    }, []);

    useEffect(() => {
        if (isPhoneVerified !== initialPhoneVerifiedState || activeMarket !== marketplaceType) {
            navigate(true);
        }
    }, [isPhoneVerified, activeMarket]);

    useEffect(() => {
        if (currLoc?.place_id) {
            setSrc(currLoc);
            navigate();
        }
        if (currLoc?.status == "failed") {
            navigate();
        }
    }, [currLoc]);

    useEffect(() => {
        (async () => {
            if (sharedLoad || sharedLorry) {
                if (Boolean(loadLorryId) && !Boolean(companyId)) {
                    setServiceType?.(CompanyServiceType.TRANSPORT_CONTRACTOR);
                    setActiveMarket(isLoadBid ? "load" : "lorry");
                    navigate(true);
                }
                if (!shouldOpenBidFormOnPageLoad) {
                    return;
                }

                if (activeMarket === "load" && isLoadBid) {
                    if (sharedLoad?.load_detail?.company_id != (customerCompanyId || companyId)) {
                        if (Boolean(companyId))
                            ValidateLoadBidding?.(() => {
                                setSelectedLoad(sharedLoad);
                                dispatchBid({
                                    type: BID_ACTIONS.LOAD,
                                    payload: {
                                        id: sharedLoad?.load_detail.id,
                                        open: true,
                                        reset: true,
                                        data: sharedLoad
                                    }
                                });
                            });
                        else {
                            setSelectedLoad(sharedLoad);
                            dispatchBid({
                                type: BID_ACTIONS.LOAD,
                                payload: { id: sharedLoad?.load_detail.id, open: true, reset: true, data: sharedLoad }
                            });
                            !Boolean(companyId) && setShouldPreventLayoutSwitch?.(true);
                        }
                    } else {
                        dispatchBid({
                            type: BID_ACTIONS.LOAD,
                            payload: { id: sharedLoad?.load_detail.id, open: false, reset: false }
                        });
                        toast.warning("You can't Bid on this load");
                    }
                }

                if ((await getCurrentState(setActiveMarket)) === "lorry" && isLorryBid) {
                    if (sharedLorry?.company_detail?.id != (customerCompanyId || companyId)) {
                        ValidateLorryBidding?.(() => {
                            setSelectedLorry(sharedLorry);
                            dispatchBid({
                                type: BID_ACTIONS.LORRY,
                                payload: {
                                    id: sharedLorry?.lorry_detail.id,
                                    open: true,
                                    reset: true,
                                    data: sharedLorry
                                }
                            });
                        });
                    } else {
                        dispatchBid({
                            type: BID_ACTIONS.LORRY,
                            payload: { id: sharedLorry?.lorry_detail.id, open: false, reset: false }
                        });
                        toast.warning("You can't Bid on this lorry");
                    }
                }
                shouldOpenBidFormOnPageLoad = false;
            }
        })();
    }, [sharedLoad, sharedLorry, isPhoneVerified, loadLorryId]);

    /***
     * handler Functions
     */
    const onChangeMktType: HeaderProps["onChangeMktType"] = (val) => {
        if (val !== activeMarket) {
            setActiveMarket(val);
            navigate();

            sendGAandMoEngageEvent({
                name:
                    val == "load"
                        ? GA4EventNames.MARKET_PLACE["marketplace_load_market_selected"]
                        : GA4EventNames.MARKET_PLACE["marketplace_lorry_market_selected"],
                data: {
                    source_screen: "marketplace"
                }
            });
        }
    };

    const navigate = async (shouldReplace: boolean = false) => {
        // all side effect should replace
        window.scroll({ left: 0, top: 0, behavior: "smooth" });
        const _activeMarket = await getCurrentState(setActiveMarket);
        const _src = await getCurrentState(setSrc);
        const _dest = await getCurrentState(setDest);
        const _filters = await getCurrentState(setFilters);

        const tempObj: any = router.query;

        if (_filters?.lorryType) {
            tempObj["l"] = _filters?.lorryType?.label.toLowerCase();
        } else if (tempObj.l) {
            delete tempObj.l;
        }

        if (_filters?.qty) {
            tempObj["q"] = _filters?.qty?.id;
        } else if (tempObj.q) {
            delete tempObj.q;
        }

        if (_filters?.verifiedVehicle) {
            tempObj["vv"] = _filters?.verifiedVehicle;
        } else if (tempObj.vv) {
            delete tempObj.vv;
        }

        let locationParam: string | undefined = undefined;
        if (_src && _dest) {
            locationParam = `${_src.name}-to-${_dest.name}`;
        } else if (_src) {
            locationParam = _src.name;
        } else if (_dest) {
            locationParam = `to-${_dest.name}`;
        }
        let arr: any = [...(query.params as string[])];
        arr[0] = _activeMarket;
        if (locationParam) {
            arr[1] = locationParam?.replace?.(/ /g, "-")?.replace?.(/,/g, "-")?.replace?.(/--/g, "-")?.toLowerCase?.();
        } else {
            arr = arr.slice(0, 1);
        }
        router.query.params = arr;

        const method = shouldReplace ? router.replace : router.push;

        await method(
            {
                pathname: router.pathname,
                query: { ...router.query, ...tempObj },
                hash: !companyId ? location.hash : undefined
            },
            undefined,
            { shallow: true }
        );
        setShouldExecuteApi(true);
    };

    const checkRestrictedEvent = (name: RestrictedEvents) => {
        switch (name) {
            case "citySearchCount":
            case "transporterSearchCount": {
                const count = Number(eventCounts?.[name] || 0);
                return count >= 3;
            }
            case "pagination": {
                return true;
            }
            default: {
                return false;
            }
        }
    };

    const checkAndHandleGuestUserRestriction = (name: RestrictedEvents) => {
        if (customerCompanyId || router.pathname.includes("/tso/")) {
            return true;
        } else if (!companyId) {
            if (checkRestrictedEvent(name)) {
                router?.push({ hash: "#Login" });
                return false;
            }
            updateEventCount?.(name);
        }
        return true;
    };

    const onSelectSrc: HeaderProps["onSelectDest"] = (selected) => {
        if (!checkAndHandleGuestUserRestriction?.("citySearchCount")) {
            return;
        }
        setSrc(selected);
        navigate();
        eventTypeSrcDestSelectClickRef.current = "src";

        sendGAandMoEngageEvent({
            name: GA4EventNames.MARKET_PLACE["marketplace_source_city_entered"],
            data: trackingEventAutoCalculatedData
        });
    };

    const onSelectDest: HeaderProps["onSelectDest"] = (selected) => {
        if (!checkAndHandleGuestUserRestriction?.("citySearchCount")) {
            return;
        }
        setDest(selected);
        navigate();
        eventTypeSrcDestSelectClickRef.current = "dest";

        sendGAandMoEngageEvent({
            name: GA4EventNames.MARKET_PLACE["marketplace_destination_city_entered"],
            data: trackingEventAutoCalculatedData
        });
    };

    const onApplyFilters: HeaderProps["onApplyFilters"] = (selectedFilters) => {
        setFilters(selectedFilters);
        navigate();
        return selectedFilters;
    };

    const {
        openModal: openDownloadAppModal,
        closeModal: closeDownloadAppModal,
        isModalOpen: isDownloadAppModalOpen
    } = useToggleModalWithLocalState(false);

    /**
     * Load posting verification check
     */
    const { ValidateLoadBidding, ValidateLorryBidding } = useValidateUserStatus();

    const trackLoadBid = (name: string, ld: LoadMarketCard, index: number, additionalData?: GenericObject) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                source_screen: "load_market",
                bid_type: "load_bid",
                rank: index,
                ...getLoadTrackData(ld),
                ...(additionalData && additionalData)
            }
        });
    };

    const trackLorryBid = (name: string, lr: LorryMarketCard, index: number, additionalData?: GenericObject) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                source_screen: "lorry_market",
                bid_type: "lorry_bid",
                rank: index,
                ...getLorryTrackData(lr),
                ...(additionalData && additionalData)
            }
        });
    };

    const onClickLoadBid = async (loadBidData: LoadMarketCard, ix: number) => {
        const onSuccess = () => {
            loadBidData?.load_detail?.is_tap_n_go &&
                router.replace(ROUTES.myLoadsMyLorries.getUrl("lorries", "bids"), undefined, { shallow: true });

            setLoadData((list) => {
                list.slice(ix, 1);
                return list;
            });
        };

        !Boolean(companyId) && setShouldPreventLayoutSwitch?.(true);

        const handleBidding = () => {
            let reset = false;
            if (loadBidData.load_detail?.id !== selectedLoad?.load_detail.id) {
                reset = true;
                setSelectedLoad(loadBidData);
            }
            dispatchBid({
                type: BID_ACTIONS.LOAD,
                payload: { id: loadBidData.load_detail?.id, open: true, reset, data: loadBidData, onSuccess }
            });
        };
        if (Boolean(companyId)) {
            ValidateLoadBidding?.(handleBidding);
        } else {
            handleBidding();
        }
    };

    const onClickLorryBid = async (item: LorryMarketCard, ix: number) => {
        !Boolean(companyId) && setShouldPreventLayoutSwitch?.(true);
        const onSuccess = () => {
            setLorryData((list) => {
                list.slice(ix, 1);
                return list;
            });
        };
        ValidateLorryBidding?.(() => {
            let reset = false;

            if (item.lorry_detail.id !== selectedLorry?.lorry_detail.id) {
                reset = true;
                setSelectedLorry(item);
            }
            dispatchBid({
                type: BID_ACTIONS.LORRY,
                payload: { id: item.lorry_detail.id, open: true, reset, data: item, onSuccess }
            });
            navigate();
        });
    };

    const pageLoadParam = getPageLoadSourceUTMParam({
        urlQueryParams: router.query,
        utmParamObj: marketplaceUtmParam
    });

    /**
     * This will prevent user from jumping directly from URL irrespective to service type
     */
    const isLoginModalOpen = router.asPath.split("#")[1]?.includes("Login");
    useEffect(() => {
        if (!router.pathname.includes("/tso/")) {
            if (serviceType === CompanyServiceType.SHIPPER) {
                onChangeMktType("lorry");
            } else if (serviceType === CompanyServiceType.FLEET_OWNER) {
                onChangeMktType("load");
            }
        }
        if (companyId && isLoginModalOpen) {
            setShouldPreventLayoutSwitch?.(true);
        } else if (companyId) {
            setShouldPreventLayoutSwitch?.(false);
        }
    }, [serviceType, companyId, isLoginModalOpen]);

    useEffect(() => {
        if (checkPermission(ADMIN_OPERATION.tagAgent) && marketplaceType) {
            setActiveMarket(marketplaceType as MarketType);
            setShouldExecuteApi(true);
        }
    }, [marketplaceType]);

    /***
     *
     */
    if (isMobile === undefined) {
        return <></>;
    }

    const DeviceWrapMain = isMobile ? Mobile : Desktop;
    const DeviceHeader = isMobile ? MobileHeader : DesktopHeader;

    const city = query.params?.[1] ? capitalize(query.params?.[1]) : "";
    const CITY_TEXT_WITHOUT_INDIA = city
        .replace("-india", "")
        .replace(/-/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    const IN_CITY_TEXT = `${city ? "in " + city : ""}`;
    const IN_CITY_TEXT_WITHOUT_INDIA = `${city ? "in " + CITY_TEXT_WITHOUT_INDIA : ""}`;
    const META_TITLE =
        activeMarket === MARKET_TYPE.LOAD
            ? city
                ? `Find Truck Load Online in ${city} - India's Top Transport Marketplace`
                : `Find Instant Truck Load Online - India's Top Transport Marketplace`
            : city
            ? `Find Online Truck Booking in ${city} - India's Top Marketplace`
            : `Book Your Truck Online : India's Top Lorry Booking Marketplace`;

    const META_DESCRIPTION =
        activeMarket === MARKET_TYPE.LOAD
            ? `Book truck loads online in ${city} with ease. Our online platform offers GST and Aadhaar Verified Transport Services, truck owners and agents at your convenience. Book now.`
            : `Find instant truck, load, and lorry bookings online in ${city} on India’s largest transport marketplace. Streamline your logistics with ease. Register & Bid Now!`;
    const META_KEYWORDS =
        activeMarket === MARKET_TYPE.LOAD
            ? `truck load booking near me, online load booking ${IN_CITY_TEXT}, load booking ${city}, find truck loads ${IN_CITY_TEXT}, find load for my truck ${IN_CITY_TEXT}, how to find load for truck, truck attachment ${IN_CITY_TEXT}`
            : `truck booking ${city}, online truck booking ${IN_CITY_TEXT}, truck booking app ${city}, mini truck transport ${IN_CITY_TEXT}, online mini truck booking ${city}`;

    return (
        <>
            <PageMetaTags
                title={META_TITLE}
                description={META_DESCRIPTION}
                keywords={META_KEYWORDS}
                canonicalUrl={`https://www.vahak.in${ROUTES.marketplace.getUrl(activeMarket, urlSrc, urlDest)}`}
                ogUrl={`https://www.vahak.in${ROUTES.marketplace.getUrl(activeMarket, urlSrc, urlDest)}`}
                ogImgSrc="https://d1rgemtytdlz2g.cloudfront.net/vahak-timeline_gvlxfa.png"
                ogImgAltText={`Search for ${
                    activeMarket === MARKET_TYPE.LOAD ? "Transport Goods, Truck Load" : "Truck, Lorries"
                } in ${
                    query.params?.[1]
                } at your fingertips! Vahak is India's #1 marketplace for online Truck booking  and Truck Loads. Connect with GST and Aadhaar verified transport services , truck owners and agents.`}
                schemaOrgJsonUrl={ROUTES.marketplace.getUrl(activeMarket, urlSrc, urlDest)}
                breadcrumbList={breadcrumbsConstants({ mktType: activeMarket }).marketplace}
                faqList={getMktContentByCity(IN_CITY_TEXT_WITHOUT_INDIA, activeMarket === MARKET_TYPE.LOAD).faqList}
            />
            <DeviceWrapMain
                className={
                    isMobile
                        ? classNames(
                              router.pathname.includes("/tso/") ? "" : Styles.main,
                              initialPhoneVerifiedState && !initialIsLoggedInState ? Styles.companyNotRegistered : ""
                          )
                        : classNames(Styles.main, isLoggedIn && Styles.isLoggedIn)
                }
                header={
                    <DeviceHeader
                        mktType={activeMarket}
                        onChangeMktType={onChangeMktType}
                        src={src}
                        dest={dest}
                        onSelectSrc={onSelectSrc}
                        onSelectDest={onSelectDest}
                        filters={filters}
                        onApplyFilters={onApplyFilters}
                        inCityText={IN_CITY_TEXT_WITHOUT_INDIA}
                    />
                }
                marketList={
                    <div className="container">
                        <ListContainer
                            title={`Available ${
                                activeMarket === MARKET_TYPE.LOAD ? "Loads" : "Lorries"
                            } ${IN_CITY_TEXT_WITHOUT_INDIA} ${
                                activeMarket === MARKET_TYPE.LOAD
                                    ? loadTotalRecords
                                        ? "(" + loadTotalRecords + ")"
                                        : ""
                                    : lorryTotalRecords
                                    ? "(" + lorryTotalRecords + ")"
                                    : ""
                            }`}
                            className={classNames(Styles.listContainer)}
                            showLoadMore={
                                !!(activeMarket === MARKET_TYPE.LOAD ? hasNextPageLoadList : hasNextPageLorryList)
                            }
                            handleClickLoadMore={() => {
                                if (!checkAndHandleGuestUserRestriction?.("pagination")) {
                                    return;
                                }
                                activeMarket === MARKET_TYPE.LOAD ? fetchNextPageLoadList() : fetchNextPageLorryList();
                            }}
                            loadingState={isLoadFetching || isLorryFetching}
                        >
                            {isPhoneVerified ? (
                                <></>
                            ) : (
                                <div className={Styles.shortDesc}>
                                    <ShowMoreText
                                        limit={
                                            isBot
                                                ? getMktContentByCity(
                                                      IN_CITY_TEXT_WITHOUT_INDIA,
                                                      activeMarket === MARKET_TYPE.LOAD
                                                  ).titleH1Desc.length
                                                : 100
                                        }
                                        truncatedEnding=""
                                        more={<Typography weight="semibold">Read More +</Typography>}
                                        text={
                                            getMktContentByCity(
                                                IN_CITY_TEXT_WITHOUT_INDIA,
                                                activeMarket === MARKET_TYPE.LOAD
                                            ).titleH1Desc
                                        }
                                    />
                                </div>
                            )}

                            <div className={classNames(Styles.listItems)}>
                                {activeMarket === MARKET_TYPE.LOAD ? (
                                    loadData?.length ? (
                                        loadData?.map((load: LoadMarketCard, index) => {
                                            if (load === undefined) return;
                                            return (
                                                <MarketplaceLoadCard
                                                    key={index}
                                                    id={`${IDs.marketPlace.marketPlaceCard}__${index}`}
                                                    data={load}
                                                    onBidNowClick={() => {
                                                        onClickLoadBid(load, index);
                                                        trackLoadBid(
                                                            GA4EventNames.MARKET_PLACE["book_now"],
                                                            load,
                                                            index + 1
                                                        );
                                                        setTrackingDataForSelectedLoad({
                                                            load: load,
                                                            index: index + 1
                                                        });
                                                    }}
                                                    handleClickProfile={() => {
                                                        trackLoadBid(
                                                            GA4EventNames.MARKET_PLACE["view_profile_clicked"],
                                                            load,
                                                            index,
                                                            {
                                                                from_screen: "load_market",
                                                                profile_verified: load?.load_detail?.is_verified
                                                            }
                                                        );
                                                    }}
                                                />
                                            );
                                        })
                                    ) : isLoadFetching ? (
                                        <></>
                                    ) : (
                                        <NoActiveLoadsLorries
                                            title="There are no active loads on the selected route!"
                                            description="Please try searching on a different route, or change the filters."
                                            image={`${IMG_BASE_URL}/no_active_loads.png`}
                                            width="260"
                                            height="170"
                                        />
                                    )
                                ) : lorryData?.length ? (
                                    lorryData?.map((lorry: LorryMarketCard, index) => {
                                        if (lorry === undefined) {
                                            return;
                                        }

                                        return (
                                            <MarketplaceLorryCard
                                                key={lorry?.lorry_detail?.id}
                                                data={lorry}
                                                id={`lorry-mkt-${index}`}
                                                onBidNowClick={() => {
                                                    onClickLorryBid(lorry, index);
                                                }}
                                                trackOnClickBidNow={() => {
                                                    trackLorryBid(GA4EventNames.MARKET_PLACE["book_now"], lorry, index);
                                                    setTrackingDataForSelectedLorry({
                                                        lorry: lorry,
                                                        index: index + 1
                                                    });
                                                }}
                                                handleClickProfile={() => {
                                                    trackLorryBid(
                                                        GA4EventNames.MARKET_PLACE["view_profile_clicked"],
                                                        lorry,
                                                        index,
                                                        {
                                                            from_screen: "lorry_market",
                                                            profile_verified: lorry?.lorry_detail?.is_verified
                                                        }
                                                    );
                                                }}
                                            />
                                        );
                                    })
                                ) : isLorryFetching ? (
                                    <></>
                                ) : (
                                    <NoActiveLoadsLorries
                                        title="There are no active lorries on the selected route!"
                                        description="Please try searching on a different route, or change the filters."
                                        image={`${IMG_BASE_URL}/no_active_lorries.png`}
                                        width="250"
                                        height="210"
                                    />
                                )}
                            </div>
                        </ListContainer>
                    </div>
                }
                VahakBenefitSlot={
                    isPhoneVerified ? null : (
                        <VahakBenefits city={CITY_TEXT_WITHOUT_INDIA} activeMarket={activeMarket} />
                    )
                }
                content={
                    isPhoneVerified
                        ? null
                        : getMktContentByCity(IN_CITY_TEXT_WITHOUT_INDIA, activeMarket === MARKET_TYPE.LOAD).content
                }
                faqList={
                    isPhoneVerified
                        ? null
                        : getMktContentByCity(IN_CITY_TEXT_WITHOUT_INDIA, activeMarket === MARKET_TYPE.LOAD).faqList
                }
            />

            {activeMarket === "load" ? (
                <LoadBidFormNew
                    trackEvent={(name) => {
                        trackLoadBid(
                            name,
                            trackingDataForSelectedLoad?.load || ({} as any),
                            trackingDataForSelectedLoad?.index || 0
                        );
                    }}
                />
            ) : (
                <></>
            )}

            {activeMarket === "lorry" && bidFormConfig.lorry.open && (
                <LorryBidForm
                    lorryDetails={selectedLorry?.lorry_detail || bidFormConfig.lorry.data.lorry_detail}
                    onSuccess={(name, data) => {
                        (trackingDataForSelectedLorry?.lorry ||
                            bidFormConfig.lorry.data ||
                            selectedLorry?.lorry_detail) &&
                            trackLorryBid(
                                name,
                                trackingDataForSelectedLorry?.lorry || bidFormConfig.lorry.data || selectedLorry,
                                trackingDataForSelectedLorry?.index || 0,
                                data
                            );
                    }}
                />
            )}

            {/* <EventScriptOnLoad eventName={"Marketplace_user_web"} params={{ value: isLoggedIn ? 1 : 0 }} />

            {pageLoadParam && (
                <EventScriptOnLoad eventName={"Marketplace_coming_location_web"} params={pageLoadParam} />
            )} */}

            {!isLoggedIn ? (
                <MandateServiceType
                    isLoggedIn={isLoggedIn}
                    initialServiceType={initialServiceType}
                    handleServiceTypeChange={onChangeMktType}
                    source="marketplace"
                />
            ) : (
                <></>
            )}
        </>
    );
};

export default MarketPlace;
