// libraries
import classNames from "classnames";
import React from "react";

// components
import ResponsiveContainer from "@vahak/core/dist/components/responsive-container/ResponsiveContainer";

// icons
import ProfileVerifyTag from "@vahak/core/dist/icons/up_verified_profile.svg";

// styles
import Styles from "./VerifiedVehicleFilterOptions.module.scss";

export type VerifiedVehicleType = boolean | undefined;

export interface VerifiedVehicleFilterOptionsProps {
    handleSelectedVerifiedVehicleFilter: (value: boolean | undefined) => void;
    selectedVerifiedVehicleFilter: VerifiedVehicleType;
    showClearBtn?: boolean;
    className?: string;
}

const VerifiedVehicleFilterOptions = ({
    selectedVerifiedVehicleFilter,
    handleSelectedVerifiedVehicleFilter,
    showClearBtn,
    className
}: VerifiedVehicleFilterOptionsProps) => {
    const onVerifiedVehicleFilter = (value?: boolean) => {
        // Callback
        handleSelectedVerifiedVehicleFilter(value === selectedVerifiedVehicleFilter ? undefined : value);
    };

    // Clear selected filter
    const onClickClear = () => {
        handleSelectedVerifiedVehicleFilter(undefined);
    };
    return (
        <div className={classNames(Styles.verifiedVehicleFilterWrapper)}>
            <div className={Styles.header}>
                <h3>Vehicle Verification Status</h3>
                {showClearBtn && (
                    <span className={Styles.clearFilter} onClick={onClickClear}>
                        Clear
                    </span>
                )}
            </div>
            <ResponsiveContainer className={classNames(Styles.verificationStatusWrapper)}>
                <div
                    onClick={() => {
                        onVerifiedVehicleFilter(true);
                    }}
                    className={classNames(
                        Styles.verificationStatus,
                        selectedVerifiedVehicleFilter && Styles.verificationStatusSelected
                    )}
                >
                    <ProfileVerifyTag width={18} height={16} />
                    <div className={classNames(Styles.verificationStatusText)}>Verified Vehicle</div>
                </div>
                {/* <div
                    onClick={() => {
                        onVerifiedVehicleFilter(false);
                    }}
                    className={classNames(
                        Styles.verificationStatus,
                        selectedVerifiedVehicleFilter === false && Styles.verificationStatusSelected
                    )}
                >
                    <div className={classNames(Styles.verificationStatusText)}>Not Verified</div>
                </div> */}
            </ResponsiveContainer>
        </div>
    );
};

export default VerifiedVehicleFilterOptions;
