import { ClientHookInfiniteQueryOptions, useVahakInfiniteQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import API from "@vahak/core/dist/_services/const";
import { generateUrlParamsFromArray } from "@vahak/core/dist/methods/generateUrlParamsFromArray";
import {
    LoadMarketType,
    LoadMarketCard,
    LoadMarketParam
} from "../../../../nextjs/services/load/useGetLoadMarketV2/type";
import queryNames from "../../queryNames";
import { generateUrlParamsFromObject } from "@vahak/core/dist/methods/generateUrlParamsFromObject";

interface aggregatedDataType {
    load_cards: LoadMarketCard[];
    page_number: number;
    total_pages: number;
    total_records: number;
}
export const useGetLoadMarketInfiniteQueryV2 = (
    params: LoadMarketParam,
    options: ClientHookInfiniteQueryOptions<LoadMarketType, unknown, aggregatedDataType>
) => {
    const {
        page,
        c_i,
        lorry_type_id,
        city_id,
        state_id,
        destination_city_id,
        destination_state_id,
        quantity_string,
        l,
        preloads,
        isAdmin,
        loadId,
        destination_place_id,
        source_place_id
    } = params;
    const { GET } = useVahakHttpClient();

    function getLoads({ pageParam = 1 }) {
        const payload = {
            page: pageParam,
            c_i,
            lorry_type_id,
            city_id,
            state_id,
            destination_city_id,
            destination_state_id,
            quantity_string,
            destination_place_id,
            source_place_id,
            l
        };
        const urlString = generateUrlParamsFromObject(payload);
        let idString = loadId && generateUrlParamsFromArray(loadId, "id");
        let preloadsString =
            preloads &&
            generateUrlParamsFromArray(
                preloads,
                "preloads",
                !isAdmin ? (["agent_details"] as LoadMarketParam["preloads"]) : undefined
            );
        return GET<LoadMarketType>(
            `${API.LOAD_MARKET_V2}${urlString}${idString ? "&" + idString : ""}${
                preloadsString ? "&" + preloadsString : ""
            }`
        );
    }

    return useVahakInfiniteQuery<LoadMarketType, unknown>([queryNames.getLoadMarketInfiniteQueryV2, params], getLoads, {
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data?.page_number < lastPage.data?.total_pages) return lastPage.data?.page_number + 1;
            return undefined;
        },
        ...options,
        onSuccess: (data) => {
            let aggregatedData: aggregatedDataType = {
                load_cards: [],
                page_number: 0,
                total_pages: 0,
                total_records: 0
            };

            data.pages.forEach((data) => {
                aggregatedData = {
                    load_cards: [...aggregatedData.load_cards, ...(data.data?.load_cards ?? [])],
                    page_number: data.data.page_number,
                    total_pages: data.data.total_pages,
                    total_records: data.data.total_records
                };
            });
            /**
             * Enclosing aggregatedData inside an array, just to fix the type script
             * as the onSuccess returns only aggregatedData[] - array type
             */
            options.onSuccess([aggregatedData]);
        },
        refetchOnWindowFocus: false
    });
};
