import { LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";

type StateType = { lorryType?: typeof LORRY_TYPES[0] } | undefined;

export function onSelectLorryTypeFilter<T extends StateType>(
    updateFilterFn?: React.Dispatch<React.SetStateAction<T>>,
    onApplyFilters?: (filters?: T) => void,
    newLorryType?: typeof LORRY_TYPES[0],
    prevFilters?: T
) {
    if (updateFilterFn) {
        updateFilterFn?.((state) => {
            if (
                (state?.lorryType?.id !== 6 && newLorryType?.id === 6) ||
                (state?.lorryType?.id === 6 && newLorryType?.id !== 6)
            ) {
                return { ...state, lorryType: newLorryType, qty: undefined };
            } else {
                return { ...state, lorryType: newLorryType };
            }
        });
        return;
    }
    let newFilters: T;
    if (
        (prevFilters?.lorryType?.id !== 6 && newLorryType?.id === 6) ||
        (prevFilters?.lorryType?.id === 6 && newLorryType?.id !== 6)
    ) {
        newFilters = { ...prevFilters, lorryType: newLorryType, qty: undefined } as any;
    } else {
        newFilters = { ...prevFilters, lorryType: newLorryType } as any;
    }
    onApplyFilters?.(newFilters);
    return newFilters;
}
