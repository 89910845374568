import classNames from "classnames";
import React from "react";
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import DropdownToggleButton from "../dropdown-toggle-button/DropdownToggleButton";
import Styles from "./MarketplaceTab.module.scss";
import DownArrowGrey from "@vahak/core/dist/icons/DownArrowGrey.svg";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";

import LoadsIcon from "@vahak/core/dist/icons/LoadsMktPlace.svg";
import LorryIcon from "@vahak/core/dist/icons/LorryMktPlace.svg";
import { useTranslation } from "next-i18next";

export interface MarketplaceTabProps {
    isMarketplaceOpen?: boolean;
    handleMarketplaceTabToggle: () => void;
    handleMarket: (val: MarketType) => void;
    selectedMarketplace: MarketType;
    containerClass?: string;
}

const MarketplaceTab = ({
    isMarketplaceOpen = false,
    handleMarketplaceTabToggle,
    handleMarket,
    selectedMarketplace,
    containerClass
}: MarketplaceTabProps) => {
    const { t } = useTranslation(["common"]);
    const _onChange = (val: MarketType) => {
        if (val !== selectedMarketplace) {
            handleMarket(val);
        }
    };
    return (
        <div className={classNames(Styles.marketplaceTabWrapper, containerClass)}>
            <Dropdown
                isOpen={isMarketplaceOpen}
                DropdownToggleBtn={
                    <DropdownToggleButton
                        label="Type"
                        subText={`${
                            selectedMarketplace === "load"
                                ? t("common:live_load_market")
                                : t("common:live_lorry_market")
                        }`}
                        icon={<DownArrowGrey />}
                        btnStyle={{ height: "60px" }}
                        buttonContentContainerStyle={{ padding: 0 }}
                        isHover={false}
                        subTextStyle={{
                            color: "#275ae6"
                        }}
                    />
                }
                handleToggle={handleMarketplaceTabToggle}
                containerClassName={Styles.marketplaceFilter}
                dropdownClassName={Styles.menuItem}
            >
                <ul className="list-group">
                    <li
                        className={`${Styles.listItem} ${selectedMarketplace === "load" && Styles.active}`}
                        onClick={() => _onChange("load")}
                    >
                        <div className={Styles.icon}>
                            <LoadsIcon />
                        </div>
                        {t("common:live_load_market")}
                    </li>
                    <li
                        className={`${Styles.listItem} ${selectedMarketplace === "lorry" && Styles.active}`}
                        onClick={() => _onChange("lorry")}
                    >
                        <div className={Styles.icon}>
                            <LorryIcon />
                        </div>
                        {t("common:live_lorry_market")}
                    </li>
                </ul>
            </Dropdown>
        </div>
    );
};

export default MarketplaceTab;
