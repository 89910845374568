// import "./desktop_header.scss";

import classNames from "classnames";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import dynamic from "next/dynamic";

import { AppContext } from "@vahak/core/dist/app-context";
import ArrowRightBlueIcon from "@vahak/core/dist/icons/AngleRightBlue.svg";

import { CitySearchResultWithCateg } from "@vahak/core/dist/custom-types/location-search";
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import useIntersect from "../../../../hooks/useIntersect";
import { usePlaceSearch } from "@vahak/core/dist/hooks/useSearch";
import { useTopDest } from "@vahak/core/dist/hooks/useTopDest";
import AppBtn, { AppBtnProps } from "@vahak/core/dist/components/app-btn/AppBtn";
import LorryTypeFilter, { LorryTypeFilterProps } from "../../common/lorry-type-filter/LorryTypeFilter";
import QuantityFilter, { QuantityFilterProps } from "../../common/quantity-filter/QuantityFilter";
import { HeaderProps } from "../../../marketplace/common/types";
import DesktopStickyHeader from "../desktop-sticky-header/DesktopStickyHeader";
import Styles from "./DesktopHeader.module.scss";
import { onSelectLorryTypeFilter } from "../../../marketplace/common/onSelectLorryTypeFilter";
import AppImg from "../../../../components/utils/AppImg";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";

import VerifiedVehicleFilter from "../../common/verified-vehicle-filter/VerifiedVehicleFilter";
import { useTranslation } from "next-i18next";
import { useFeatureByServiceType } from "../../../../hooks/useFeatureByServiceType";
import ChangeServiceType from "../../common/change-service-type/ChangeServiceType";
import IDs from "../../../../constants/automation";
import IfCanAccess from "../../../admin/IfCanAccess/IfCanAccess";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GenericObject } from "@vahak/core/dist/custom-types/generic-object";
import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ADMIN_OPERATION } from "../../../../constants/adminOperation";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";
const Search = dynamic(() => import("@vahak/core/dist/components/search/Search"), {
    ssr: false
});

export interface DesktopHeaderRef {
    src: CitySearchResultWithCateg | undefined;
    dest: CitySearchResultWithCateg | undefined;
}

interface DesktopHeaderProps extends HeaderProps {}

const DesktopHeader = forwardRef<DesktopHeaderRef, DesktopHeaderProps>(
    (
        { mktType, onChangeMktType, src, onSelectSrc, dest, onSelectDest, filters, onApplyFilters, inCityText },
        forwardedRef
    ) => {
        const { sendGAandMoEngageEvent } = useEventTrackerService();
        const { checkPermission } = useCsDashboard();
        const isTso = checkPermission(ADMIN_OPERATION.tagAgent);
        const isLoadMkt = mktType === "load";
        const trackingEventAutoCalculatedData = {
            source_screen: isLoadMkt ? "load_market" : "lorry_market",
            filter: isLoadMkt ? "load_market" : "lorry_market",
            from_city: src?.name,
            from_state: src?.state,
            to_city: dest?.name,
            to_state: dest?.state,
            filter_selection: [
                ...(filters?.qty ? [filters?.qty?.label] : []),
                ...(filters?.lorryType ? [filters?.lorryType?.label] : [])
            ],
            ...(isTso && {
                source_screen: isLoadMkt ? "tso_load_market" : "tso_lorry_market"
            })
        };
        const { t } = useTranslation(["common"]);
        const { recentTransporterCitySearches, companyId, name, isPhoneVerified, userId, serviceType } =
            useContext(AppContext);

        const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);

        const { targetRef, isIntersecting } = useIntersect({ threshold: 0 });

        useEffect(() => {
            setIsStickyHeaderVisible(!isIntersecting);
        }, [isIntersecting]);

        const _onChangeMktType: AppBtnProps["onClick"] = (e) => {
            onChangeMktType((e.target as HTMLLIElement).dataset?.val as MarketType);
        };

        const trackClick = (selection_from: string, name: string, additionalData: GenericObject = {}) => {
            const data = {
                ...trackingEventAutoCalculatedData,
                selection_from: selection_from,
                ...additionalData
            };

            sendGAandMoEngageEvent({
                name,
                data
            });
        };

        const { topDests: _topDests, setTopDests } = useTopDest({ max: 2 });

        const {
            isSearchLoading: isSrcSearchLoading,
            labelKey: srcLabelKey,
            onItemSelected: onSrcSelected,
            onSearchInput: onSrcSearchInput,
            searchOptions: srcSearchOptions,
            // selectedItem: src,
            searchText: srcSearchText,
            // setSearchText: setSrcSearchText,
            onFocus: onSrcSearchFocus
        } = usePlaceSearch({
            locationParamsOption: {
                allowState: true,
                source: isLoadMkt
                    ? LocationSearchSource.LoadMarketPlaceSearchSource
                    : LocationSearchSource.LorryMarketPlaceSearchSource
            },
            showCurrentLocationOption: true,
            defaultOptions: recentTransporterCitySearches,
            onSelectItem: (selected: any) => {
                onSelectSrc(selected[0]);
            }
        });

        const {
            isSearchLoading: isDestSearchLoading,
            labelKey: destLabelKey,
            onItemSelected: onDestSelected,
            onSearchInput: onDestSearchInput,
            searchOptions: destSearchOptions,
            // selectedItem: dest,
            searchText: destSearchText,
            // setSelectedItem: setSelectedDest,
            onFocus: onDestSearchFocus
        } = usePlaceSearch({
            locationParamsOption: {
                allowState: true,
                source: isLoadMkt
                    ? LocationSearchSource.LoadMarketPlaceSearchDestination
                    : LocationSearchSource.LorryMarketPlaceSearchDestination
            },
            showCurrentLocationOption: true,
            defaultOptions: recentTransporterCitySearches,
            onSelectItem: (selected: any) => {
                onSelectDest(selected[0]);
                setTopDests(selected[0]);
            }
        });

        // const onClickDestQuickFilter = (selectedItem: CitySearchResultWithCateg) => {
        //     setSelectedDest(selectedItem);
        //     onSelectDest(selectedItem);
        //     setTopDests(selectedItem);
        // };

        // const onDeselectDestQuickFilter = () => {
        //     setSelectedDest(undefined);
        //     // onSelectDest(undefined);
        //     // setTopDests(selectedItem);
        // };

        const onSelectLorryType: LorryTypeFilterProps["handleSelectedLorryType"] = (lorryType) => {
            // onApplyFilters({ ...filters, lorryType });
            const result = onSelectLorryTypeFilter(undefined, onApplyFilters, lorryType, filters);
            const trackingData = {
                filter_selection: [
                    ...(result?.qty ? [result?.qty?.label] : []),
                    ...(result?.lorryType ? [result?.lorryType?.label] : [])
                ]
            };

            if (lorryType) {
                trackClick("vehicle_type", GA4EventNames.MARKET_PLACE["filters_selected"], trackingData);
            } else {
                trackClick("vehicle_type", GA4EventNames.MARKET_PLACE["filters_cleared"], trackingData);
            }
        };

        const onSelectQty: QuantityFilterProps["handleSelectedQuantity"] = (qty) => {
            const result = onApplyFilters?.({ ...filters, qty });
            const trackingData = {
                filter_selection: [
                    ...(result?.qty ? [result?.qty?.label] : []),
                    ...(result?.lorryType ? [result?.lorryType?.label] : [])
                ]
            };
            if (qty) {
                trackClick("vehicle_capacity", GA4EventNames.MARKET_PLACE["filters_selected"], trackingData);
            } else {
                trackClick("vehicle_capacity", GA4EventNames.MARKET_PLACE["filters_cleared"], trackingData);
            }
        };

        const onSelectVerifiedVehicle = (verifiedVehicle: boolean | undefined) => {
            onApplyFilters?.({ ...filters, verifiedVehicle });
            sendGAandMoEngageEvent({
                name: GA4EventNames.MARKET_PLACE["filters_selected"],
                data: trackingEventAutoCalculatedData
            });
        };

        const [isDestinationSearchVisible] = useState(false);
        const [focusDestSearch] = useState(0);

        // const onClickDestinationSearchBtn = async () => {
        //     // setSelectedDest(undefined);
        //     setDestinationSearchVisible(true);
        //     await sleep(500);
        //     setFocusDestSearch((state) => state + 1);
        // };

        const { showLoadFeatures, showLorryFeatures } = useFeatureByServiceType();
        return (
            <>
                <section className={Styles.main} ref={targetRef}>
                    <div className={Styles.cover}>
                        <div className="container">
                            <div className={Styles.coverRow}>
                                <div className={Styles.textBox}>
                                    <h1>{`Find Online ${isLoadMkt ? "Load" : "Lorry"} Booking ${inCityText}`}</h1>
                                </div>
                                <div className={Styles.imageBox}>
                                    <AppImg
                                        src={`banner_image.png`}
                                        alt={`Find Online ${isLoadMkt ? "Load" : "Lorry"} Booking ${inCityText}`}
                                        width="545"
                                        height="234"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className={classNames(Styles.searchPanel, "loadLorrysearchPanel")}>
                            <div className={Styles.loadLorryMarketTab}>
                                {showLoadFeatures ? (
                                    <div className={classNames(Styles.col, !showLorryFeatures ? Styles.singleTab : "")}>
                                        <AppBtn
                                            data-val="load"
                                            className={classNames(
                                                Styles.liveLoadBtn,
                                                mktType === "load" ? Styles.selected : ""
                                            )}
                                            onClick={_onChangeMktType}
                                            id={IDs.marketplace.marketLoad}
                                        >
                                            {t("common:live_load_market")}
                                        </AppBtn>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {showLorryFeatures ? (
                                    <div className={classNames(Styles.col, !showLoadFeatures ? Styles.singleTab : "")}>
                                        <AppBtn
                                            data-val="lorry"
                                            className={classNames(
                                                Styles.liveLorryBtn,
                                                mktType === "lorry" ? Styles.selected : ""
                                            )}
                                            onClick={_onChangeMktType}
                                            id={IDs.marketplace.marketLorry}
                                        >
                                            {t("common:live_lorry_market")}
                                        </AppBtn>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className={Styles.sourceDestSavedRow}>
                                <div className={Styles.sourceCol}>
                                    <h3>SOURCE LOCATION</h3>
                                    <div className={Styles.searchInputBox}>
                                        <Search
                                            isLoading={isSrcSearchLoading}
                                            id="src-search"
                                            labelText=""
                                            onSearch={onSrcSearchInput}
                                            onSelectItem={onSrcSelected}
                                            options={srcSearchOptions}
                                            labelKey={srcLabelKey}
                                            selectedItem={src}
                                            searchText={srcSearchText}
                                            onFocus={onSrcSearchFocus}
                                            placeholder="Search Loading City"
                                            minLength={0}
                                            inputWrapperClassName={Styles.searchWrapper}
                                            containerClassName={Styles.searchContainer}
                                        />
                                    </div>
                                </div>
                                <div className={Styles.destCol}>
                                    <h3>DESTINATION LOCATION</h3>
                                    <span className={Styles.rightArow}>
                                        <ArrowRightBlueIcon />
                                        <ArrowRightBlueIcon />
                                    </span>
                                    <div className={Styles.searchInputBox}>
                                        <Search
                                            isLoading={isDestSearchLoading}
                                            id="dest-search"
                                            labelText=""
                                            onSearch={onDestSearchInput}
                                            onSelectItem={onDestSelected}
                                            options={destSearchOptions}
                                            labelKey={destLabelKey}
                                            selectedItem={dest}
                                            searchText={destSearchText}
                                            onFocus={onDestSearchFocus}
                                            placeholder="Search Unloading City"
                                            minLength={0}
                                            setFocus={focusDestSearch}
                                            showClearOption={!!dest}
                                            inputWrapperClassName={Styles.searchWrapper}
                                            containerClassName={classNames(
                                                Styles.searchContainer,
                                                isDestinationSearchVisible ? Styles.showDest : ""
                                            )}
                                        />
                                    </div>

                                    {/* <div className={Styles.searchInputBox}>
                                        <div className={Styles.QuickFilterCol}>
                                            {_topDests.slice(0, 2).map((state) => (
                                                <QuickFilterPill
                                                    key={state?.state_id}
                                                    text={state?.state}
                                                    suffix={
                                                        state?.state_id === dest?.state_id ? (
                                                            <CheckBlackIcon />
                                                        ) : (
                                                            <GreyPlusIcon />
                                                        )
                                                    }
                                                    isMobile={false}
                                                    isSelected={state?.state_id === dest?.state_id}
                                                    onSelected={onClickDestQuickFilter}
                                                    onDeselected={onDeselectDestQuickFilter}
                                                    className={Styles.dest_search_quick_filter}
                                                    data={state}
                                                />
                                            ))}

                                            <div className={Styles.searchBtnWrap}>
                                                <span
                                                    className={Styles.searchBtn}
                                                    onClick={onClickDestinationSearchBtn}
                                                >
                                                    Search
                                                    <SearchBlueIcon />
                                                </span>
                                            </div>

                                            <Search
                                                isLoading={isDestSearchLoading}
                                                id="dest-search"
                                                labelText=""
                                                onSearch={onDestSearchInput}
                                                onSelectItem={onDestSelected}
                                                options={destSearchOptions}
                                                labelKey={destLabelKey}
                                                selectedItem={dest}
                                                searchText={destSearchText}
                                                placeholder="Search Unloadinng City"
                                                minLength={0}
                                                setFocus={focusDestSearch}
                                                inputWrapperClassName={Styles.searchWrapper}
                                                containerClassName={classNames(
                                                    Styles.searchContainer,
                                                    isDestinationSearchVisible ? Styles.showDest : ""
                                                )}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                <div className={Styles.savedCol}>
                                    {/* <AppBtn className={Styles.lovedBtn}>
                                        <HeartHallowBlue />
                                        Saved
                                    </AppBtn> */}
                                    {/* {companyId && (
                                        <Link href={`${hostURL}/${ROUTES.bidHistory.getUrl(mktType)}`} passHref>
                                            <AppBtn
                                                className={classNames(Styles.lovedBtn, Styles.historyBtn)}
                                            >
                                                <HistoryBlue />
                                                History
                                            </AppBtn>
                                        </Link>
                                    )} */}
                                </div>
                            </div>
                            {/* <DesktopHistoryHelp /> */}
                        </div>
                        <div className={classNames(Styles.filterSection)}>
                            <span className={classNames(Styles.heading)}>Filter by:</span>

                            <LorryTypeFilter
                                className={Styles.dropdownFilter}
                                handleSelectedLorryType={onSelectLorryType}
                                selectedLorryType={filters?.lorryType}
                                showClearBtn
                                onToggle={() => {
                                    sendGAandMoEngageEvent({
                                        name: GA4EventNames.MARKET_PLACE["filters_clicked"],
                                        data: trackingEventAutoCalculatedData
                                    });
                                }}
                            />
                            <QuantityFilter
                                className={Styles.dropdownFilter}
                                selectedQty={filters?.qty}
                                handleSelectedQuantity={onSelectQty}
                                showClearBtn
                                showLitres={filters?.lorryType?.id === 6}
                                onToggle={() => {
                                    sendGAandMoEngageEvent({
                                        name: GA4EventNames.MARKET_PLACE["filters_clicked"],
                                        data: trackingEventAutoCalculatedData
                                    });
                                }}
                            />
                            <IfCanAccess action={""} allowCsUser alsoCheck={mktType === "lorry"}>
                                <VerifiedVehicleFilter
                                    className={Styles.dropdownFilter}
                                    handleSelectedVerifiedVehicleFilter={onSelectVerifiedVehicle}
                                    selectedVerifiedVehicleFilter={filters?.verifiedVehicle}
                                    showClearBtn
                                />
                            </IfCanAccess>
                            {!(isPhoneVerified && companyId) ? <ChangeServiceType /> : <></>}
                        </div>
                    </div>
                </section>

                {isStickyHeaderVisible ? (
                    <DesktopStickyHeader
                        isVisible={isStickyHeaderVisible}
                        onChangeMktType={onChangeMktType}
                        mktType={mktType}
                        src={src}
                        onSelectSrc={onSelectSrc}
                        dest={dest}
                        onSelectDest={onSelectDest}
                        filters={filters}
                        onApplyFilters={onApplyFilters}
                    />
                ) : (
                    <></>
                )}
            </>
        );
    }
);

export default DesktopHeader;
