import React, { useContext } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";

//Style
import Styles from "./ChangeServiceType.module.scss";

//Components
import Typography from "@vahak/core-ui/dist/components/Typography";
import Button from "@vahak/core-ui/dist/components/Button";

//Icons
import EditIconBlue from "@vahak/core/dist/icons/edit_icon_blue.svg";

//Hooks
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

//Context
import { AppContext } from "@vahak/core/dist/app-context";

//Services
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { MARKET_TYPE } from "@vahak/core/dist/constants/market";

interface ChangeServiceTypeProps {
    isStickyHeader?: boolean;
}

const ChangeServiceType = ({ isStickyHeader }: ChangeServiceTypeProps) => {
    const mobileScreen = useMediaQuery({ queryType: "mobile" });
    const router = useRouter();
    const mktQuery = router.query["params"]?.[0];
    const isLoadMkt = mktQuery === MARKET_TYPE.LOAD;
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const trackOnClick = () => {
        sendGAandMoEngageEvent({
            name: GA4EventNames.MARKET_PLACE["change_preference_clicked"],
            data: {
                source_screen: isLoadMkt ? "load_market" : "lorry_market"
            }
        });
    };

    const { serviceType } = useContext(AppContext);

    const getServiceTypeName = () => {
        switch (serviceType) {
            case CompanyServiceType.AGENT_BROKER:
            case CompanyServiceType.TRANSPORT_CONTRACTOR:
            case CompanyServiceType.DEPRECATED_SERVICE_TYPE:
                return "Load & Lorry Marketplace";
            case CompanyServiceType.SHIPPER:
                return "Lorry Marketplace";
            case CompanyServiceType.FLEET_OWNER:
                return "Load Marketplace";

            default:
                return "Load & Lorry Marketplace";
        }
    };
    return (
        <div className={classnames(Styles.ChangeServiceType, isStickyHeader ? Styles.stickyHeader : "")}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                {mobileScreen ? <></> : <Typography size="m">Current Preference: </Typography>}
                <Typography size="m" mSize="s" weight="medium">
                    {getServiceTypeName()}
                </Typography>
            </div>
            <Button
                onClick={() => {
                    router.push({ hash: "#changeServiceType" });
                    trackOnClick();
                }}
                fillType="text"
                startIcon={<EditIconBlue />}
            >
                <Typography size="m" mSize="s" weight="regular">
                    Change
                </Typography>
            </Button>
        </div>
    );
};

export default ChangeServiceType;
