import React, { useContext, useState } from "react";
import classNames from "classnames";

// Inner Component
import MarketplaceTab from "../marketplace-tab/MarketplaceTab";

// Style
import Styles from "./DesktopStickyHeader.module.scss";
// import "../desktop-header/desktop_header.scss";

import LocationSearch from "../location-search/LocationSearch";

// Components
import DesktopAllFilters from "../desktop-all-filters/DesktopAllFilters";
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";

// Assets
// import { ReactComponent as HeartHallowBlue } from "@vahak/core/dist/icons/heartHallowBlue.svg";
import HistoryBlue from "@vahak/core/dist/icons/historyBlue.svg";
import { HeaderProps } from "../../../marketplace/common/types";
import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import Link from "next/link";
import { AppContext } from "@vahak/core/dist/app-context";
import ChangeServiceType from "../../common/change-service-type/ChangeServiceType";

interface DesktopStickyHeaderProps extends HeaderProps {
    isVisible: boolean;
}

const DesktopStickyHeader = ({
    isVisible,
    mktType,
    onChangeMktType,
    src,
    onSelectSrc,
    dest,
    onSelectDest,
    filters,
    onApplyFilters
}: DesktopStickyHeaderProps) => {
    const { companyId, isPhoneVerified } = useContext(AppContext);

    const [isMarketplaceOpen, setIsMarketplaceOpen] = useState(false);
    const { phoneNum, name } = useContext(AppContext);
    const toggleMarketplaceDropdown = () => {
        setIsMarketplaceOpen(!isMarketplaceOpen);
    };

    return (
        <section
            className={classNames(
                Styles.main,
                isPhoneVerified && companyId && Styles.isLoggedIn,
                isPhoneVerified && !companyId && Styles.companyRegistrationRequired,
                isVisible ? Styles.visible : Styles.hidden
            )}
        >
            <div className={Styles.mainRow}>
                <div className={Styles.stickyHeaderContainer}>
                    {/* Marketplace toggle dropdown */}
                    {isPhoneVerified && companyId ? (
                        <MarketplaceTab
                            containerClass={Styles.marketplaceTab}
                            isMarketplaceOpen={isMarketplaceOpen}
                            handleMarketplaceTabToggle={toggleMarketplaceDropdown}
                            handleMarket={(val) => {
                                onChangeMktType(val);
                                toggleMarketplaceDropdown();
                            }}
                            selectedMarketplace={mktType}
                        />
                    ) : (
                        <ChangeServiceType isStickyHeader />
                    )}

                    {/* Location search filter */}
                    <LocationSearch
                        mktType={mktType}
                        src={src}
                        onSelectSrc={onSelectSrc}
                        dest={dest}
                        onSelectDest={onSelectDest}
                    />

                    {/* All filter */}
                    <DesktopAllFilters selectedFilters={filters} onClickApplyFilter={onApplyFilters} showClearAllBtn />

                    {/* Saved loads and lorries */}
                    {/* <div className={Styles.savedCol}>
                            <AppBtn className={Styles.savedBtn}>
                                <HeartHallowBlue /> Saved
                            </AppBtn>
                        </div> */}

                    {/* Search History */}
                    {/* {companyId ? (
                            <div className={Styles.historyCol}>
                                <Link
                                    href={ROUTES.bidHistory.getUrl(mktType)}
                                    // style={{
                                    //     pointerEvents: !isVisible ? "none" : "unset",
                                    //     cursor: !isVisible ? "unset" : "pointer"
                                    // }}

                                    passHref
                                >
                                    <AppBtn
                                        className={Styles.historyBtn}
                                        fillType="outline"
                                        type="primary"
                                    >
                                        <HistoryBlue /> History
                                    </AppBtn>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )} */}
                </div>
            </div>
        </section>
    );
};

export default DesktopStickyHeader;
