import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "@vahak/core/dist/app-context";

//  components
import AppBtn from "@vahak/core/dist/components/app-btn/AppBtn";
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import LorryTypeFilterOptions, {
    LorryTypeFilterOptionsProps
} from "../../common/lorry-type-filter-options/LorryTypeFilterOptions";
import { onSelectLorryTypeFilter } from "../../../marketplace/common/onSelectLorryTypeFilter";
import QuantityFilterOptions, {
    QuantityFilterOptionsProps
} from "../../common/quantity-filter-options/QuantityFilterOptions";
import { Filters } from "../../../marketplace/common/types";
import DropdownToggleButton from "../dropdown-toggle-button/DropdownToggleButton";
// styles
import Styles from "./DesktopAllFilters.module.scss";

interface DesktopAllFiltersProps {
    selectedFilters: Filters | undefined;
    onClickApplyFilter: (filters: Filters | undefined) => void;
    showClearAllBtn?: boolean;
}

const DesktopAllFilters = ({ selectedFilters, onClickApplyFilter, showClearAllBtn }: DesktopAllFiltersProps) => {
    const { companyId } = useContext(AppContext);

    const [tempFilters, updateTempFilters] = useState<Filters>();

    const [isOpen, setIsOpen] = useState(false);

    const [selectedFiltersString, setSelectedFiltersString] = useState("Add Filters");

    const handleToggle = () => {
        setIsOpen((state) => !state);
        if ((selectedFilters?.lorryType || selectedFilters?.qty) === undefined) {
            updateTempFilters(undefined);
        }
    };

    const onSelectLorryType: LorryTypeFilterOptionsProps["handleSelectedLorryType"] = (lorryType) => {
        onSelectLorryTypeFilter(updateTempFilters, undefined, lorryType);
        // updateTempFilters((state) => ({ ...state, lorryType }));
    };

    const onSelectQuantity: QuantityFilterOptionsProps["handleSelectedQuantity"] = (qty) => {
        updateTempFilters((state) => ({ ...state, qty }));
    };

    const _onClickApply = () => {
        onClickApplyFilter(tempFilters);
        setIsOpen(false);
    };

    const onClearAllFilter = () => {
        updateTempFilters(undefined);
        setSelectedFiltersString("Add Filters");
        onClickApplyFilter(undefined);
        setIsOpen(false);
    };

    useEffect(() => {
        updateTempFilters(selectedFilters);

        // Concat selected filters label
        let allSelectedFilters = [];

        selectedFilters?.lorryType && allSelectedFilters.push(selectedFilters.lorryType.label);
        selectedFilters?.qty && allSelectedFilters.push(selectedFilters.qty.label);
        allSelectedFilters.length > 0
            ? setSelectedFiltersString(allSelectedFilters.join(", "))
            : setSelectedFiltersString("Add Filters");
    }, [selectedFilters]);

    // Concat selected filters label
    let allSelectedFilter = [];

    tempFilters?.lorryType && allSelectedFilter.push(tempFilters.lorryType.label);
    tempFilters?.qty && allSelectedFilter.push(tempFilters.qty.label);
    return (
        <Dropdown
            DropdownToggleBtn={
                <DropdownToggleButton
                    label="Filter"
                    subText={selectedFiltersString}
                    btnStyle={{ height: "60px" }}
                    buttonContentContainerStyle={{ padding: 0 }}
                    isFilterSelected={
                        (selectedFilters && (selectedFilters.lorryType || selectedFilters.qty)) !== undefined
                    }
                    isOpen={isOpen}
                />
            }
            isOpen={isOpen}
            handleToggle={handleToggle}
            dropdownClassName={classNames(Styles.main, Styles.right)}
        >
            <div className={Styles.contentWrapper}>
                <div className={Styles.header}>
                    <h3 className={Styles.title}>FILTERS</h3>
                    {showClearAllBtn && (
                        <span className={Styles.clearAllFilter} onClick={onClearAllFilter}>
                            Clear all filters
                        </span>
                    )}
                </div>
                <div className={classNames(Styles.desktopAllFilterWrapper)}>
                    {/* Lorry type filter */}
                    <div className={Styles.lorryTypeFilter}>
                        <LorryTypeFilterOptions
                            handleSelectedLorryType={onSelectLorryType}
                            selectedLorryType={tempFilters?.lorryType}
                        />
                    </div>

                    {/* Quantity filter */}
                    <div className={Styles.quantityTypeFilter}>
                        <QuantityFilterOptions
                            handleSelectedQuantity={onSelectQuantity}
                            selectedQty={tempFilters?.qty}
                            showLitres={tempFilters?.lorryType?.id === 6}
                        />
                    </div>
                </div>
                <div className={Styles.applyBtnContainer}>
                    <AppBtn className={Styles.applyBtn} isBlockBtn onClick={_onClickApply}>
                        Apply filters {allSelectedFilter.length > 0 && `(${allSelectedFilter.length})`}
                    </AppBtn>
                </div>
            </div>
        </Dropdown>
    );
};

export default DesktopAllFilters;
