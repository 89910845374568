// libraries
import React, { useState } from "react";

// Components
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import FilterToggleButton from "../../desktop/filter-toggle-button/FilterToggleButton";
import QuantityFilterOptions, { QuantityFilterOptionsProps } from "../quantity-filter-options/QuantityFilterOptions";
// Styles
// import Styles from "./QuantityFilter.module.scss";

// Assets
import VehicleCapacity from "@vahak/core/dist/icons/nf_vehicle_capacity_grey.svg";

export interface QuantityFilterProps extends QuantityFilterOptionsProps {
    className?: string;
    onToggle?: () => void;
}

const QuantityFilter = ({
    className,
    selectedQty,
    handleSelectedQuantity,
    onToggle,
    ...props
}: QuantityFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleQuantityFilterToggle = () => {
        setIsOpen((state) => !state);
        onToggle?.();
    };

    const _onSelectQty: QuantityFilterOptionsProps["handleSelectedQuantity"] = (val) => {
        handleSelectedQuantity(val);
        setIsOpen(false);
    };
    return (
        <Dropdown
            isOpen={isOpen}
            DropdownToggleBtn={
                <FilterToggleButton
                    type="quantity"
                    label={selectedQty?.label || "Vehicle Capacity"}
                    icon={selectedQty && VehicleCapacity}
                    isOpen={isOpen}
                />
            }
            handleToggle={handleQuantityFilterToggle}
            containerClassName={className}
        >
            <QuantityFilterOptions handleSelectedQuantity={_onSelectQty} selectedQty={selectedQty} {...props} />
        </Dropdown>
    );
};

export default QuantityFilter;
