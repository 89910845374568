import React, { useState } from "react";

// Components
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import FilterToggleButton from "../../desktop/filter-toggle-button/FilterToggleButton";
import VerifiedVehicleFilterOptions, {
    VerifiedVehicleType
} from "./verified-vehicle-filter-options/VerifiedVehicleFilterOptions";

// icons
import ProfileVerifyTag from "@vahak/core/dist/icons/up_verified_profile.svg";
import ProfileVerifyDefault from "@vahak/core/dist/icons/up_verified_profile_default.svg";

// styles
import Styles from "./VerifiedVehicleFilter.module.scss";

export interface VerifiedVehicleFilterProps {
    handleSelectedVerifiedVehicleFilter: (value: boolean | undefined) => void;
    selectedVerifiedVehicleFilter: VerifiedVehicleType;
    showClearBtn?: boolean;
    className?: string;
}

const VerifiedVehicleFilter = ({
    selectedVerifiedVehicleFilter,
    handleSelectedVerifiedVehicleFilter,
    className,
    ...props
}: VerifiedVehicleFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleVerifiedVehicleFilterToggle = () => {
        setIsOpen((state) => !state);
    };

    const _onSelect = (val: boolean | undefined) => {
        handleSelectedVerifiedVehicleFilter(val);
        setIsOpen(false);
    };

    return (
        <Dropdown
            isOpen={isOpen}
            DropdownToggleBtn={
                <FilterToggleButton
                    type="vehicle"
                    label={
                        selectedVerifiedVehicleFilter ? (
                            <span className={Styles.verifiedVehicleFilter}>
                                <ProfileVerifyTag width={18} height={16} />
                                <span className={Styles.vehicleVerificationStatus}> Verified Vehicle</span>
                            </span>
                        ) : (
                            <span className={Styles.verifiedVehicleFilter}>
                                <ProfileVerifyDefault width={18} height={16} />
                                <span className={Styles.vehicleVerificationStatus}>Vehicle Status</span>
                            </span>
                        )
                    }
                    icon={""}
                    isOpen={isOpen}
                />
            }
            handleToggle={handleVerifiedVehicleFilterToggle}
            containerClassName={className}
        >
            <VerifiedVehicleFilterOptions
                selectedVerifiedVehicleFilter={selectedVerifiedVehicleFilter}
                handleSelectedVerifiedVehicleFilter={_onSelect}
                {...props}
            />
        </Dropdown>
    );
};

export default VerifiedVehicleFilter;
